import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import { Section,  Input, Form } from "../../components/Modules"
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import { Link } from 'react-router-dom';

class ConfirmSignup extends Component {
    state = {
        code:null,
        username:null,
        loading:false
    }
    componentDidMount(){
      setTimeout(()=>{
        this.setState({
          loading:true
        })
      },2000)
     // console.log(this.props,'this is props')
    }
    confirmSignUp = async event => {
        const { username,code } = this.state
        try {
            await Auth.confirmSignUp(username, code);
            this.props.history.push('/')
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }
    login = event => {
        this.props.history.push('/')
    }
  render() {
    return (
      <section className="section auth">
        <div className="container">
        <Section className="login">
            <Logo isLoading={this.state.loading}/>
            <h1>Confirm Signup</h1>
            <Form>
              <form >
                  <fieldset>
                      <Input type="text" id="username"  name="username" value={this.state.oldpassword} placeholder="username" onChange={(x)=>this.setState({username:x.target.value})} isLoading={this.state.loading} focus={true}/>
                      <Input type="text" id="code"  name="code" value={this.state.newpassword} placeholder="code" onChange={(x)=>this.setState({code:x.target.value})} isLoading={this.state.loading}/>
                  </fieldset>
                  <div className="multiBtn">                     
                      <Button className="single" title="Change Password" type="button" styles="primary" handleClick={this.confirmSignUp} isLoading={this.state.loading}/>
                  </div>
                  <p>Do you wanna login? <Link to="/">Login Now!</Link></p>
              </form>
            </Form>
          </Section>
        </div>
      </section>
    );
  }
}

export default ConfirmSignup;