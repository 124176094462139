import React, { Component } from "react";
import { Section, Header } from "../../components/Modules";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import view from "../../images/view.svg";
import Skeleton from "react-loading-skeleton"
import { useMediaQuery } from 'react-responsive'

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}


class websiteListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          search: "",
          isLoading: true,
          loop: 4,
          items: []
        };
      }
      searchSpace = (event) => {
        this.setState({ search: event.target.value });
      };
      componentDidMount() {
    
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 2000);
        
      }
    

    render(){
        function StyleSkeleton() {
            return (
              <tr width="100%">
                 <td className="profileIcon"><Skeleton circle={true} width="40px" height="40px" /></td>

                  <td>
                    <label>Name</label>
                    <p>{<Skeleton />}</p>
                  </td>
                  <td>
                    <label>Url</label>
                    <p><Skeleton /></p>
                  </td>
              
                {/* <td style={{ width: "15%" }}>
                  <label>Security</label>
                  <p>{<Skeleton />}</p>
                </td>
                <td style={{ width: "15%" }}>
                  <label>Optimization</label>
                  <p>{<Skeleton />}</p>
                </td>
                <td style={{ width: "15%" }}>
                  <label>Firewall</label>
                  <p>{<Skeleton />}</p>
                </td> */}
                <td style={{ width: "10%" }}>
                  <label>
                    <br />
                  </label>
                  <p>{<Skeleton />}</p>
                </td>
              </tr>
            );
          }
          const Weblist = styled.div`
             {
              text-align: left;
              display: inline-block;
              width: 100%;
              img {
                border-radius: 100%;
                width: 150px;
                height: 150px;
                float: left;
                padding: 5px;
              }
              .name {
                width: 38%;
                display: inline-block;
                padding: 30px;
      
                h3 {
                  color: #1d3557;
                  font-size: 34px;
      
                  margin: auto;
                }
                p {
                  color: #a7a7a7;
                  font-size: 12px;
                }
              }
      
              .websiteCount {
                width: 18%;
                height: 150px;
                display: none;
                padding: 30px;
                float: right;
                border-left: 1px solid #ddd;
      
                p {
                  color: #a7a7a7;
                  font-size: 18px;
                  margin: 30px 0;
                }
              }
      
              .websitePercentage {
                width: 15%;
                height: 150px;
                display: none;
                float: right;
                padding: 30px;
                border-left: 1px solid #ddd;
                h3 {
                  color: #44c4a1;
                  font-size: 82px;
                  margin: auto;
                }
                p {
                  color: #a7a7a7;
                  font-size: 18px;
                }
              }
              @media (max-width: 1024px) {
                .name{width: calc(100% - 235px);}
                .websiteCount, .websitePercentage{
                  width: 50%;box-sizing: border-box;
                  h3{line-height:48px}
                  p{margin: 12px 0 30px;}
                }
              }
              @media (max-width: 767px) {}
            }
          `;
          const WebsiteTable = styled.div`
             {
              //display: flex;
              text-align: left;
      
              
              img {
                margin-left: 10px;
              }
              label {
                font-size: 12px;
                color: #a7a7a7;
              }
              p {
                font-size: 22px;
                font-weight: 600;
                color: #1d3557;
                margin: auto;
                padding: 0px;
                padding-left: 0;
                a{
                  color: #1d3557;
                }
              }
              .redcol {
                color: #e63946;
                font-size: 22px;
                font-weight: 600;
                margin: auto;
                padding: 0px;
                padding-left: 0;
              }
              .greencol {
                color: #44c4a1;
                font-size: 22px;
                font-weight: 600;
                margin: auto;
                padding: 0px;
                padding-left: 0;
              }
              table {
                width: 100%;
                //border-collapse: collapse;
                border-style: solid;
                border-color: #d6d6d6;
                border-radius: 10px;
                border-width: 1px;
                box-shadow: 0 3px 10px rgba(0,0,0,0.2);
                tr, td{
                  &:hover{
                    background-color:#f1f1f1;
                  }
                }
              }
              td,
              tr {
                padding: 12px 10px 18px 10px;
                border-bottom: 1px solid #d6d6d6;
              }
              tr:last-child > td {
                border-bottom: none;
              }
              .profileIcon{
                padding-right:0;
                img{
                  margin-top:6px;
                  border-radius: 100px;
                  border: 1px solid #ccc;
                  width:40px;
                  height:40px;
                }
              }

              @media (max-width: 1024px) {
                table tr td:nth-child(4), table tr td:nth-child(5), table tr td:nth-child(6), table tr td.action a span {
                    display: none;
                }
                p{
                  font-size:20px;
                }
                td.action label {
                  display: none;
                }
                td.action a img {
                    float: left;
                }
                .profileIcon{
                  width: 60px;
                }
              }
            }
          `;

        const { companies, match } = this.props;
        const websites = companies && companies.payload.filter(item=> item._id === match.params.id.replace('=',''))
        
        const { search } = this.state;
        const lowercasedFilter = search.toString().toLowerCase();
        const filteredData =
        websites && websites[0] && websites[0].websites.length > 0 &&
        websites[0].websites.filter(item => {
            return item.name.toLowerCase().indexOf(lowercasedFilter.toLowerCase()) !== -1;
          });

        return (
            <section className="section auth">
        <div className="container">
          <Section width={"95%"} padding={"25px"}>
            <Header hideViewButton={true}/>
            <br />
            <br />
            <Weblist className="detailBar">
              <div className="logo">{this.state.isLoading ? <Skeleton style={{ float: "left" }} circle={true} height={150} width={150}></Skeleton> : <img alt="" src={websites[0] && websites[0].company_logo} style={{objectFit:"cover"}} />}</div>

              <div className="name">
                <h3>{this.state.isLoading ? <Skeleton /> : websites[0].name}</h3>
                <p>{this.state.isLoading ? <Skeleton /> : websites[0].description}</p>
              </div>
              <div className="websitePercentage">
                <h3>{this.state.isLoading ? <Skeleton /> : "80%"}</h3>
                <p>Websites listed secure and completed</p>
              </div>
              <div className="websiteCount">
                <p>{this.state.isLoading ? <Skeleton /> : websites[0].websites && websites[0].websites.length + " websites listed"}</p>
                <p className="improve" style={{ color: "#e63946" }}>
                  {this.state.isLoading ? <Skeleton /> : "5 websites needs improvment"}
                </p>
              </div>
            </Weblist>
            <br />
            <br />
           
           
            <div className="search searchFilter">
                <input type="text" placeholder="Search Website By Name" onChange={(e) => this.searchSpace(e)} />
            </div>

            <WebsiteTable>
           

              <table>
                {this.state.isLoading ? (
                  <>
                    <StyleSkeleton />
                    <StyleSkeleton />
                    <StyleSkeleton />
                    <StyleSkeleton />
                  </>
                ) : (
                    filteredData.length > 0 ? filteredData.map((items, ind) => (
                    <>
                    <Mobile>
                      <tr className="listingMobile" style={{marginBottom:'5px'}} >
                      <td className="profileIcon">{this.state.isLoading ? <Skeleton circle={true} width="40px" height="40px" /> : <img alt="" src={view} />}</td>
                       <td><p>{items.name}</p><label>{items.website_url}</label> </td> 
                       <td width="70"> <Link to={"list=" + items._id}>{this.state.isLoading ? <Skeleton circle={true} width="16px" height="16px" /> : <img alt="" src={view} width="16" height="16" style={{paddingRight:"15px"}} />}</Link></td>
                      </tr>
                    </Mobile>
                    <Default>
                    <tr>
                      <td className="profileIcon">{this.state.isLoading ? <Skeleton circle={true} width="40px" height="40px" /> : <img alt="" src={view} />}</td>

                      <td>
                        <label>Name</label>
                        <p>{items.name}</p>
                      </td>
                      <td>
                        <label>Url</label>
                        <p><a href={items.website_url}>{items.website_url}</a></p>
                      </td>
                      {/* <td>
                        <label>Security</label>

                        <p className={securecond === "secured" ? "greencol" : "redcol"}>Secured</p>
                      </td>
                      <td>
                        <label>Optimization</label>
                        <p className={optcond === "optimized" ? "greencol" : "redcol"}>Optimizated</p>
                      </td>
                      <td>
                        <label>Firewall</label>
                        <p className={firewall === "enabled" ? "greencol" : "redcol"}>Enabled</p>
                      </td> */}
                      <td className="action" valign="middle" style={{display:'table-cell' }}>
                        <Link to={"list=" + items._id} style={{ color: "#e63946", fontSize: "13px", float: "right", padding: "0px 15px 0 0", fontWeight: "500" }}>
                          <img alt="" src={view} style={{ width: "16px", height: "16px", paddingRight: "8px", position: "relative", top: "2px" }} /> <span>VIEW WEBSITE DASHBOARD</span>
                        </Link>
                      </td>
                    </tr>
                    </Default>
                    </>
                  )) : <h2 style={{textAlign:"center",width:"100%"}}>Website are not listed</h2>
                )}
              </table>
            </WebsiteTable>
          </Section>
        </div>
      </section>
        )
    }
}
const mapStateToProps = (state) => ({companies: state.companies})
export default connect(mapStateToProps)(websiteListing)