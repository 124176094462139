import React, { Component, Fragment } from "react";
import styled from "styled-components";
import {Tick, Info} from "../images/icons";
import Button from './Button';
export class Ticket extends Component{
  state = {
    active: false,
    items : this.props.data,
    ticketfordetail:[],
    detailshow:false
  };

  
  render(){
    const count=[]
  return(
      <section className="section auth">
          <div>
              <div className={this.state.active === true ? "ticketToggle active":"ticketToggle"} >
                  <h1 onClick={()=>this.setState({active:this.state.active === true ? false : true})}><span></span>Add Ticket</h1>   
                  <div className="Tick">
                    <textarea placeholder="Write Ticket Title" style={{height:"25px"}}></textarea>
                    <textarea placeholder="Message" style={{height:"200px"}}></textarea>
                    <div><a href="javascript"/></div>
                    <div className="uploadforticket">
                      <input type="file"  />
                      <Button title="Upload File" styles="primary"/>
                    </div>
                    <Button title="Create Now" styles="secondary"/>
                  </div>
              </div>
          </div>
          
          <h1>Tickets List</h1>            
          <TicketTable>
          <table>
              <tr>
                  <th>TITLE</th>
                  <th>VIEW</th>
                  <th>COMMENTS</th>
                  <th>STATUS</th>
              </tr>
              {this.state.items ?  this.state.items.map((ticketlist,ind)=>              
              <Fragment key={ind}>
                {count.push(0) &&
                ticketlist.comments.map((ticket)=>{count[ind] = ticket.commentRead === 0 ? count[ind] + 1 :count[ind] + 0 }) }
                {/* {console.log(this.state.unreadcount,ind)} */}
              <tr>
                  <td>
                      <p>{ticketlist.title}</p>
                  </td>
                  <td>
                      <Button title="View Details" styles="tableview" padding="10px 20px" onClick={()=>this.setState({ticketfordetail:ticketlist[ind],detailshow:true})}/>
                  </td>
                  <td>
                      <p>{/*ticketlist.comments && ticketlist.comments.commentRead ? ticketlist.comments.length +*/
                      count[ind] === 0 ? "--" :count[ind] === 1 ? "1 Unread Comment" : count[ind] + " Unread Comments"}</p>
                  </td>
                  <td>
                  <p className={ticketlist.status === "active" ? "orange"
                  : ticketlist.status === "pending" ?  "orange"
                  : ticketlist.status === "" ?  "grey" : ""}>
                      {ticketlist.status === "complete"  ? Tick: Info} &nbsp;
                      {ticketlist.status === "active" ? "In Progress" 
                      : ticketlist.status === "pending" ? "Pending" 
                      : ticketlist.status === "complete" ? "Completed" : "Unknown" }</p>
                  </td>
              </tr>
              </Fragment>           
              )
              :"No Ticket Found"}
          </table>
          </TicketTable>
          {}
          <TicketDetail>
            <h1>ticket title</h1> 
            <p>message</p>
            <h1>Comments</h1>  


          </TicketDetail>
      </section>
    )
  }
}

const TicketDetail=styled.div`

`;
const TicketTable = styled.div`
{
    text-align: left;
    th {
        font-size: 18px;
        color: #1d3557;
        font-weight: 600;
    }
    p {
      font-size: 16px;
      color: #1d3557;
      margin: auto;
      padding: 0px;
      padding-left: 0;
      font-weight: 500;
    }
    table {
      width: 100%;
      border-style: solid;
      border-color: #d6d6d6;
      border-radius: 10px;
      border-width: 1px;
      box-shadow: 0 3px 10px rgba(0,0,0,0.2);
      tr, td{
        &:hover{
          background-color:#f1f1f1;
        }
        .orange svg path#filler {
            fill:rgb(255 156 25);
        }
        .yellow svg path#filler {
            fill:rgb(243 240 44);
        }
        .grey svg path#filler {
            fill:rgb(162 162 158);
        }
      }
    }
    td,th,
    tr {
      padding: 20px 20px;
      border-bottom: 1px solid #d6d6d6;
    }
    tr:last-child > td {
      border-bottom: none;
    }

    @media (max-width: 1024px) {
      table tr td:nth-child(4), table tr td:nth-child(5), table tr td:nth-child(6), table tr td.action span {
          display: none;
      }
      p{
        font-size:20px;
      }
      td.action label {
        display: none;
      }
      td.action a img {
          float: left;
      }
      .profileIcon{
        width: 60px;
      }
    }
  }
`;
export default Ticket;