import styled from "styled-components";
import { Loader } from "../images/icons";



// var loader = true
export const Section = (props) => {
  var data = JSON.parse(localStorage.getItem('applicationState'));
  var dataTheme = data && data.theme
  // window.onload = (event) => {
  //   loader = false
  // };
  return (
    <SectionWrapper width={props.width} padding={props.padding} className={props.className} styleNew={dataTheme && dataTheme.theme}>
      {props.children}
      {/* { data && <div className="loader">{Loader}</div>} */}
    </SectionWrapper>
  );
};


const SectionWrapper = styled.div`
  background: #fff;
  max-width: ${({ width }) => (width ? width : "882px")};
  width: 100%;
  margin: 35px auto;
  box-shadow: 0px 0px 34px rgb(51 51 51 / 25%);
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: ${({ padding }) => (padding ? padding : "50px")};
  box-sizing: border-box;
  overflow: hidden;
  min-height:400px;
  display:inline-block;
  h1 {
    font-size: 32px;
    font-family: "Montserrat", sans-serif;
    color: #1d3557;
    font-weight: normal;
    margin: 22px 0 40px 0;
  }

  ${({styleNew})=>
  `
  a:hover{
    text-decoration:underline;
    color: ${styleNew ? styleNew.primary.substring(styleNew.primary.lastIndexOf("rgb")).split('100%')[0] : 'none'}
    
  }
  `};
  
  @media (width: 768px) {
    // max-width: 675px;
    // padding: 8.5% 20px;
    //border-radius:0px;
    //padding: 27px;
    //box-shadow:none;
  }

  @media (max-width: 767px) {
    margin-top:10px;
    margin-bottom:30px;
    position: relative;
    top: 0;
    transform: translate(0px, 0px);
    left: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0px 10px;
    max-width: 100%;
    h1 {
      font-size: 20px;
    }
  }
`;
