import styled from "styled-components";

export const SectionView = (props) => {
  return (
    <SectionWrapper width={props.width} padding={props.padding} className={props.className}>
      {props.children}
    </SectionWrapper>
  );
};
const SectionWrapper = styled.div`
  background: #fff;
  max-width: ${({ width }) => (width ? width : "882px")};
  width: 100%;
  margin: 35px auto;
  box-shadow: 0px 0px 34px rgb(51 51 51 / 25%);
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: ${({ padding }) => (padding ? padding : "50px")};
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  :before{
    content: '';
    clear: both;
  } 
  :after{
    content: '';
    clear: both;
  }
  .before {
    width: 20%;
    background: gray;
    display: inline-block;
  }
  h1 {
    font-size: 32px;
    font-family: "Montserrat", sans-serif;
    color: #1d3557;
    font-weight: normal;
    margin: 22px 0 40px 0;
  }

  @media (width: 768px) {
    max-width: 675px;
    padding: 8.5% 20px;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 0;
    box-shadow: none;
  }
  @media (max-width: 767px) {
    position: relative;
    top: 0;
    transform: translate(0px, 0px);
    left: 0;
    border-radius: 0;
    box-shadow: none;
    padding:0;
    max-width: 100%;
    padding: 0;
    box-shadow: none;

    h1 {
      font-size: 20px;
    }
  }
`;
