import React, { Component } from 'react';
import FormErrors from "./formError/formErrors";
import Validate from "../../components/auth/FormValidation";
import { Auth } from 'aws-amplify';
import { Section,  Input, Form } from "../../components/Modules"
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import { Link } from 'react-router-dom';
import { Loader } from "../../images/icons";
import { connect } from "react-redux";


class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: "",
    email: "",
    newpassword: "",
    errors: {
      cognito: null,
      blankfield: false
    },
    loader: this.props.state.theme ? false:true
  };

  componentDidMount(){
    setTimeout(()=>{
      this.setState({
        loading:true
      })
    },2000)
   // console.log(this.props,'this is props')
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  passwordVerificationHandler = async event => {
    event.preventDefault();
    this.setState({
      loader: true,
    });

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
        loader: false
      });
    }

    // AWS Cognito integration here
    try{
      await Auth.forgotPasswordSubmit(
          this.state.email,
          this.state.verificationcode,
          this.state.newpassword
      );
      this.props.history.push('/changepasswordconfirmation');
    }catch(error){
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
        loader: false,
      });
    }

  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };
  login = event => {
      this.props.history.push('/')
  }

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <Section className="login">
            <Logo  isLoading={true}/>
            <h1>Set new password</h1>
            <p>Please enter the verification code sent to your email address below, <br/> your email address and a new password.</p>
            {console.log(this.state.errors)}
            <FormErrors formerrors={this.state.errors} />
            <Form>
              <form onSubmit={this.passwordVerificationHandler}>
                  <fieldset>
                      <Input type="text" id="verificationcode"  name="verificationcode" value={this.state.verificationcode} placeholder="Enter verification code" onChange={this.onInputChange} isLoading={true}/>
                      <Input type="text" id="email"  name="email" value={this.state.email} placeholder="Enter email" onChange={this.onInputChange} isLoading={true}/>
                      <Input type="password" id="newpassword"  name="newpassword" value={this.state.newpassword} placeholder="New password" onChange={this.onInputChange} isLoading={true}/>
                  </fieldset>
                  <div className="multiBtn">                     
                      <Button className="single" title="Submit" type="submit" styles="primary" isLoading={true} />
                  </div>
                  <p>Do you wanna login? <Link to="/">Login Now!</Link></p>
                {this.state.loader && <div className="loader">{Loader}</div>}
              </form>
            </Form>
          </Section>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(ForgotPasswordVerification);