import styled from "styled-components";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from 'react-responsive'
import { connect } from "react-redux";

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

class Button extends Component {
  render() {
    return this.props.themeStyling ? (
      <ButtonField
        className={this.props.className}
        padding={this.props.padding}
        type={this.props.type}
        buttonType={this.props.styles}
        buttonStyle={this.props.themeStyling ? this.props.themeStyling.theme && this.props.themeStyling.theme : null }
        onClick={this.props.handleClick}
      >
        {this.props.title}
      </ButtonField>
    ) : (
      <>
      <Mobile><Skeleton height={60} style={{marginBottom:'10px'}}/></Mobile>
      <Default>
        <StyleSkeleton>
          <Skeleton width={250} height={60} />
        </StyleSkeleton>
      </Default>
      </>
    );
  }
}

const ButtonField = styled.button`
  border: 0px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 13px;
  color: #fff;
  outline: none;
  cursor: pointer;
  padding: ${({ padding }) => (padding ? padding : "18px 20px")};
  margin: 0px 5px;

  ${({ buttonType, buttonStyle }) =>
    buttonType === "primary" &&
    `
        background: rgb(29,53,87);
        background: ${buttonStyle ? buttonStyle.primary: 'rgb(29,53,87)' };
        &:hover{
          background:${buttonStyle.primary.replace('90deg','270deg')};
        }
  `}
  ${({ buttonType, buttonStyle }) =>
    buttonType === "secondary" &&
    `
        background: rgb(230,57,70);
        background:${buttonStyle ? buttonStyle.secondary: 'rgb(230,57,70)'};
        &:hover{
          background:${buttonStyle.secondary.replace('90deg','270deg')};
        }
    `}
    ${({ buttonType, buttonStyle }) =>
    buttonType === "firewallenabled" &&
    `
        background: rgb(255,255,255);
        color:#44C4A1;
        
    `}
    

    ${({ buttonType, buttonStyle }) =>
    buttonType === "firewalldisabled" &&
    `
        background: rgb(255,255,255);
        color:#E63946;
    `}

    ${({ buttonType, buttonStyle }) =>
    buttonType === "tableview" &&
    `
        background: rgb(68,196,186);
        color:#fff;
        text-transform:none;
        font-weight:500;
        font-size: 16px;
    `}

    ${({ buttonType, buttonStyle }) =>
    buttonType === "downloadpdf" &&
    `
        background: rgb(255,255,255);
        background:${buttonStyle.downloadpdf};
        color:#E63946;
        
    `}
`;

const StyleSkeleton = styled.div`
  padding: 10px;
  margin: 0 auto;
  display: inline;
  span {
    border-radius: 10px;
  }
  
`;

const mapStateToProps = (state) => ({ themeStyling: state.theme });
export default connect(mapStateToProps)(Button);
