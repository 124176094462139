import { Link } from "react-router-dom";
import React, { Component } from "react";
import { Calender, Database, Firewall, Perftest, Perfopt, Report, Comments, Plugin, Theme, Uptime, TicketIcon } from "../images/icons"
import { connect } from "react-redux";

class Sidebar extends Component {
  state = { 
    data:this.props.data
  }
  render() {
    console.log(this.state.data)
    return (
        <ul className="sidebarUl" style={{color:this.props.themeStyling.textColor}}>
        {/* theme */}
        <li className={this.state.data === "weekly" ? "active":""} onClick={()=>this.setState({data:"weekly"})}>
          <Link to="/single_page/weekly">
            {Calender} <span>Weekly Updates</span>
          </Link>
        </li>
        <li className={this.state.data === "backup" ? "active":""} onClick={()=>this.setState({data:"backup"})}>
          <Link to="/single_page/backup">
            {Database} <span>SITE & Database Backups</span>
          </Link>
        </li>
        <li className={this.state.data === "report" ? "active":""} onClick={()=>this.setState({data:"report"})}>
          <Link to="/single_page/report">
            {Report} <span>Monthly Reporting</span>
          </Link>
        </li>
        <li className={this.state.data === "optimization" ? "active":""} onClick={()=>this.setState({data:"optimization"})}>
          <Link to="/single_page/optimization">
            {Perfopt} <span>Performance Optimization</span>
          </Link>
        </li>
        <li className={this.state.data === "plugins" ? "active":""} onClick={()=>this.setState({data:"plugins"})}>
          <Link to="/single_page/plugins">
            {Plugin} <span>Plugins Update Status</span>
          </Link>
        </li>
        <li className={this.state.data === "firewall" ? "active":""} onClick={()=>this.setState({data:"firewall"})}>
          <Link to="/single_page/firewall">
            {Firewall} <span>Firewall Settings</span>
          </Link>
        </li>
        <li className={this.state.data === "theme" ? "active":""} onClick={()=>this.setState({data:"theme"})}>
          <Link to="/single_page/theme">
            {Theme} <span>Theme Update Status</span>
          </Link>
        </li>
        <li className={this.state.data === "performance" ? "active":""} onClick={()=>this.setState({data:"performance"})}>
          <Link to="/single_page/performance">
            {Perftest} <span>Performance Test</span>
          </Link>
        </li>
        <li className={this.state.data === "monitoring" ? "active":""} onClick={()=>this.setState({data:"monitoring"})}>
          <Link to="/single_page/monitoring">
            {Uptime} <span>Uptime Monitoring</span>
          </Link>
        </li>
        <li className={this.state.data === "ticket" ? "active":""} onClick={()=>this.setState({data:"ticket"})}>
          <Link to="/single_page/ticket">
             {TicketIcon}<span>Tickets</span>
          </Link>
        </li>
        <li className={this.state.data === "comments" ? "active":""} onClick={()=>this.setState({data:"comments"})}>
          <Link to="/single_page/comments">
             {Comments}<span>Spam Comments</span>
          </Link>
        </li>
        </ul>
    );
  }
}

const mapStateToProps = (state) => ({ themeStyling: state.theme });
export default connect(mapStateToProps)(Sidebar);
