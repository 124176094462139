import React, { Component } from "react";
import styled from "styled-components";
import Moment from 'react-moment';
// import Skeleton from "react-loading-skeleton";
// import pdf from "../images/pdf.svg";
import { Pdf } from "../images/icons"

class Reportbox extends Component {
  state = {};
  render() {
    return (
      <Box className="reportBoxDiv">
        <a  href={this.props.viewLink} target="_new">
        <div style={{width:'100%'}}>
          <span className="month">
            <Moment format="MMMM">{this.props.datetime}</Moment>
            <a href={this.props.viewLink} target="_new" className="link" >
              Download Report
            </a>
          </span>
          <span className="datetime"><Moment format="DD - YYYY - HH:mm">{this.props.datetime}</Moment></span>
        </div>
        <div className="pdf">
          {Pdf}
        </div>
        </a>
      </Box>
    );
  }
}
const Box = styled.div`
       {
        // padding: 10px;
        display: inline-flex;
        font-size: 18px;
        border-style: solid;
        border-color: #d6d6d6;
        border-radius: 10px;
        border-width: thin;
        width: calc(33.33% - 2%);
        margin:1%;
        height: 64px;
        box-shadow: 2px 2px #dddddd;
        box-sizing: border-box;
        &:hover {
          background: #e63946;
          .month {
            color: white;
          }
          .datetime {
            color: white;
          }
          .link {
            color: #511419;
            background: #e63946;
          }
          .pdf svg path {
            fill:#fff;
          }
        }
        img {
          padding: 10px;
          margin: auto;
          height: 44px;
          float: right;
        }
        .month {
          color: #1d3557;
          font-weight: 500;
          font-size: 18px;
          float: left;
          line-height: 23px;
        }
        .datetime {
          color: #a7a7a7;
          font-size: 12px;
          position: relative;
          top: 5px;
          float: left;
        }
        .link {
          color: #e63946;
          font-size: 10px;
          font-weight: bold;
          height: 13px;
          width: 113px;
          display:block;
          text-transform:uppercase;
        }
        .pdf svg {
          width: 37px;
          height: 44px;
          position: relative;
          top: 11px;
          right:10px;
        }
        .pdf {
          float: right;
        }
        span {
          padding: 10px;
        }

        @media (min-width: 1024px) and (max-width: 1400px){
          width: 47.5%;
          float: left;
        }        
        
        @media (max-width: 1024px) {
          width: 47.5%;
          float: left;
        }
        @media (max-width: 767px) {
          width:100%;
          float: left;
          margin: 1% 0;}
      }
    `;
export default Reportbox;
