import React, { Component } from "react"
import "./App.css"
import Amplify, { Auth } from "aws-amplify"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login from "./pages/auth/login"
import Register from "./pages/auth/register"
import ForgotPassword from "./pages/auth/forgetPassword"
import ForgotPasswrodVerification from "./pages/auth/forgotPasswrodVerification"
import ChangePassword from "./pages/auth/changePassword"
import ChangePasswordConfirm from "./pages/auth/changePasswordConfirm"
import ConfirmSignup from "./pages/auth/confirmSignup"
import WebsiteListing from "./pages/agency/listing"
import CompanyListing from "./pages/agency/index"
import WebsiteListingClient from "./pages/client/index"
import Dashboard from "./pages/dashboard/index"
import Loading from "./pages/404/Loading"
import View from "./pages/dashboard/view"
import awsmobile from "./aws-exports";
import API from "@aws-amplify/api";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux"
import { requestGeneralSettings } from './store/action'
import Landing from "./pages/Landing"
import Admin from "./pages/admin/index"

// Amplify.configure(awsmobile);
API.configure();

//old
// function FetchData() {
//   const fetcher = (url) => API.get("bridge", "/general").then((response) => response && response.data);
//   const { data, error } = useSWR("/", fetcher);
//   return "";
// }

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: false,
      user: null,
    };
  }

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };
  setUser = (user) => {
    this.setState({ user: user });
    localStorage.setItem("user", JSON.stringify(user));
  };
  callbackFunction = () => {
    console.log("done");
  };

  async componentDidMount() {
    

    try {
      //const session = await Auth.currentSession()
      this.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
      //console.log(session, user, "user");

      // if (typeof window !== 'undefined') {
      // 	let spDm = window.location.hostname.split('.')
      // 	let spAr = spDm[0]
      // 	console.log(spAr)
      //   } else {
      // 	// work out what you want to do server-side...
      //   }
    } catch (error) {
      //console.log(error);
    }
    this.setState({ isAuthenticating: false });
  }

  componentDidUpdate(){
    var data = JSON.parse(localStorage.getItem('applicationState'));
    var dataTheme = data && data.theme
    const fav=document.getElementById("favicon");
    const appfav=document.getElementById("apple-touch-favicon");
    appfav.href = dataTheme.theme && dataTheme.theme.favicon ?  dataTheme.theme.favicon :  window.location+"/favicon.png";
    fav.href = dataTheme.theme && dataTheme.theme.favicon ?  dataTheme.theme.favicon : window.location+"/favicon.png";
  }

	render() {
    const { state } = this.props

		const authProps = {
			isAuthenticated: this.state.isAuthenticated,
			user: this.state.user ? this.state.user : JSON.parse(localStorage.getItem('user')),
		  	setAuthStatus: this.setAuthStatus,
			  setUser: this.setUser
    }

    state.theme && 
    Amplify.configure({
      ...awsmobile,
      Auth: { 
        mandatorySignId: true,
        region: "us-east-1", 
        "userPoolId": state.theme.cognito.pools_id && state.theme.cognito.pools_id,
        "userPoolWebClientId": state.theme.cognito.client_id && state.theme.cognito.client_id
      },
      "aws_user_pools_id": state.theme.cognito.pools_id && state.theme.cognito.pools_id,
      "aws_user_pools_web_client_id": state.theme.cognito.client_id && state.theme.cognito.client_id
    });
    
    // console.log(Amplify._config,'Amplify')
    let user = JSON.parse(localStorage.getItem('user'));

		return (
      <Router>
			<div className='App'>
          {/* <Route path='/' exact render={(props) => <Landing {...props} auth={authProps} />} /> */}
          {/* {user && user.signInUserSession.refreshToken.token ? <Redirect to='/dashboard' />  :  <Redirect to='/landing' /> } */}
         
          
					{user && user.signInUserSession.refreshToken.token && state.userDetails && state.userDetails.type ?  
          
              state.userDetails.type === 'agency' ? 
               <Switch>
                  <Route path='/' exact render={(props) => (<Route component={Landing} />)} />
                  <Route path='/dashboard' exact render={(props) => (<CompanyListing {...props} auth={authProps} />)} />
                  <Route path='/website/id:id' exact render={(props) => (<WebsiteListing {...props} auth={authProps} />)} />
                  <Route path='/website/list:id' exact render={(props) => (<Dashboard {...props} auth={authProps} />)} />
                  <Route path='/single_page/:page'  render={(props) => (<View {...props} auth={authProps} />)} />
                  <Route path='/admin' exact render={(props) => (<Admin {...props} auth={authProps} />)} />
                  <Route component={Landing} />
                  </Switch>
              : state.userDetails.type === 'client' &&
                <Switch>
                  <Route path='/' exact render={(props) => (<Route component={Landing} />)} />
                  {/* <Route path='/dashboard' exact render={(props) => (<CompanyListing {...props} auth={authProps} />)} /> */}
                  <Route path='/dashboard' exact render={(props) => (<WebsiteListingClient {...props} auth={authProps} />)} />
                  <Route path='/list:id' exact render={(props) => (<Dashboard {...props} auth={authProps} />)} />
                  <Route path='/single_page/:page'  render={(props) => (<View {...props} auth={authProps} />)} />
                  <Route path='/admin' exact render={(props) => (<Admin {...props} auth={authProps} />)} />
                  {/* <Route path='/dashboard' exact render={(props) => ( <WebsiteListingClient {...props} auth={authProps} />  )} />  */}
                   <Route component={Landing} />
                </Switch>
              
						: 
            <Switch>
              <Route path='/' exact render={(props) => (<Login {...props} auth={authProps} />)} />
              <Route path='/admin' exact render={(props) => (<Admin {...props} auth={authProps} />)} />
              <Route path='/register'render={(props) => (<Register {...props} auth={authProps} />)} />
              <Route path='/forgotpassword'render={(props) => (<ForgotPassword {...props} auth={authProps} />)} />
              <Route path='/forgotpasswordverification'render={(props) => (<ForgotPasswrodVerification {...props} auth={authProps} />)} />
              <Route path='/changepassword'render={(props) => (<ChangePassword {...props} auth={authProps} />)} />
              <Route path='/changepasswordconfirmation'render={(props) => (<ChangePasswordConfirm {...props} auth={authProps} />)} />
              <Route path='/confirmsignup'render={(props) => (<ConfirmSignup {...props} auth={authProps} />)} /> 
              <Route component={Landing} />
            </Switch>
            }
            
        
				
			</div>
      </Router>
		)
  }
}

const mapStateToProps = (state) => ({ state });
const mapDispatchToProps = (dispatch) => bindActionCreators(requestGeneralSettings, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);
