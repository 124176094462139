import React, { Component } from 'react';
import FormErrors from "./formError/formErrors";
import Validate from "../../components/auth/FormValidation";
import { Section,  Input,  Form } from "../../components/Modules"
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import { Auth } from 'aws-amplify';
import { Loader } from "../../images/icons";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

class ForgotPassword extends Component {
  state = {
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    },
    loader: true
  }

  componentDidMount(){
    // setTimeout(()=>{
    //   this.setState({
    //     loading:true
    //   })
    // },2000)
   // console.log(this.props,'this is props')
    var data = JSON.parse(localStorage.getItem('applicationState'));  
    data.theme != null &&
   setTimeout(()=>{
     this.setState({
       loader:false
     })
   },1000)
  }
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler = async event => {
    event.preventDefault();
    this.setState({
      loader: true,
    });
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
        loader: false,
      });
    }
    // AWS Cognito integration here
    try{
      await Auth.forgotPassword(this.state.email);
      this.props.history.push('/forgotpasswordverification');
    }catch(error){
      console.log(error);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
        loader: false,
      });
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }
  login = event => {
      this.props.history.push('/')
  }

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <Section className="login">
            <Logo  isLoading={this.state.loader}/>
            <h1>Forgot your password?</h1>
            <p>Please enter the email address associated with your account and we'll <br/> email you a password reset link.</p>
            {console.log(this.state.errors)}
            <FormErrors formerrors={this.state.errors} />
            <Form>
              <form onSubmit={this.forgotPasswordHandler}>
                  <fieldset>
                      <Input type="text" id="email"  name="email" value={this.state.email} placeholder="Username / Email Adress" onChange={this.onInputChange}  isLoading={true} focus={true}/>
                  </fieldset>
                  <div className="multiBtn">                    
                    <Button className="single" title="Submit" type="submit" styles="primary"  isLoading={this.state.loader}/>
                  </div>
                  <p>Do you wanna login? <Link to="/">Login Now!</Link></p>
                {this.state.loader && <div className="loader">{Loader}</div>}
              </form>
            </Form>
          </Section>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(ForgotPassword);
