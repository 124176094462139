import React, { Component } from "react";
import { Section, Header } from "../../components/Modules";
import { requestCompanyList } from "../../store/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import arrow from "../../images/arrow.svg";
import { ArrowDropdown, ArrowDropdownUp } from "../../images/icons"
import Skeleton from "react-loading-skeleton";

class CompanyListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      isLoading: true,
      activeIndex:null
    };
  }
  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };
  componentDidMount() {
    this.props.dispatch(requestCompanyList());
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 2000);
  }
  companyListing = () => {};
  
  handleClick(index) {
    this.setState({ activeIndex: index === this.state.activeIndex ? null:index });
  }

  render() {
    function Skeletonstyle() {
      return (
        <div className="item">
          <div className="header">
            <Skeleton height="160px" />
          </div>
          <div className="description">
            <h3>
              <Skeleton width="100px" height="30px" />
            </h3>

            <p>
              <Skeleton width="100%" height="20px" />
            </p>

            <span className="listing">
              <Skeleton width="100%" height="20px" />
            </span>
          </div>
          <Footer>
            <Skeleton width="75%" height="25px" style={{ marginBottom: "10px" }} />
          </Footer>
        </div>
      );
    }
    const { companies } = this.props;
    const { search } = this.state;
    const lowercasedFilter = search.toString().toLowerCase();
    const filteredData =
      companies.payload !== "loading..." &&
      companies.payload.filter((item) => {
        return Object.keys(item).some((key) => typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter));
      });

    return (
      <section className="section auth">
        <div className="container">
          <Section width={"95%"} padding={"25px"} className="mainContainer">
            <Header />
            <Head>
              <h1> Company Listing </h1>
              <div className="search">
                <input type="text" placeholder="Search Company..." id="search" onChange={(e) => this.searchSpace(e)} />
              </div>
            </Head>

            <div className="companyListing">
              {/* {companies.payload != "loading..." ? */}
              {this.state.isLoading ? (
                <>
                  <Skeletonstyle />
                  <Skeletonstyle />
                  <Skeletonstyle />
                  <Skeletonstyle />
                </>
              ) : (
                filteredData.length > 0 ? filteredData.map((item, i) => (
                  <div className={this.state.activeIndex === i ? "item" : "item active"} key={i}>
                    <div className="arrowDropdown"  onClick={()=>this.handleClick(i)} >{this.state.activeIndex === i ? ArrowDropdownUp:ArrowDropdown}</div>
                    <div className="header">
                      <img src={item.company_logo} alt={item.name} />
                    </div>
                    <div className="description">
                      <h3>{item.name}</h3>
                      <p>Lorem Ipsum is simply dummy text</p>
                      <span className="listing"> {item.websites.length > 0 ? item.websites.length + " website listed" : "No website listed"} </span>
                      <span className="improvelisting"> websites needs improvment </span>
                      {this.state.activeIndex !== i && <div className="mbArrow"><Link to={"/website/id="+item._id}>View Websites <img alt="" src={arrow} style={{ marginLeft: "10px" }} /></Link></div>}
                    </div>
                    <Footer className="footer">
                      <Link to={"/website/id="+item._id}>
                        {/*to={this.props.viewLink}*/}
                        View Websites <img alt="" src={arrow} style={{ marginLeft: "10px" }} />
                      </Link>
                    </Footer>
                   
                  </div>
                )) : <h2 style={{textAlign:"center",width:"100%"}}>Companies are not listed</h2>
              )}
            </div>
          </Section>
        </div>
      </section>
    );
  }
}

const Head = styled.div`
   {
    text-align: left;
    margin: 0 15px;
    padding-top:30px;
    h1 {
      color: #1d3557;
      font-size: 34px;
      font-weight: 600;
      display: inline-block;
    }
    .search {
      padding: 0px;
      float: right;
      position: relative;
      top: 15px;

      input {
        border-radius: 50px;
        padding: 12px 10px;
        border: 2px solid #E8E8E8;
        font-size: 14px;
        outline: none;
        width: 280px;
        font-family: "Montserrat", sans-serif;
        //box-shadow: 0 6px 10px -3px #bfbfbf;
        box-shadow: 0 6px 10px -3px #ddd;
        border-width: thin;
      }
    }

  }
  @media (max-width: 767px) {
    margin-bottom:30px;
    h1{margin:0;font-size:28px;padding-bottom:20px;}
    .search {
      padding: 0;
      float: none;
      top: 0;
      input{
        width:100%;
        box-sizing: border-box;
      }
    }
  }
  
`;
const Footer = styled.div`
  text-align: left;
  width: 100%;
  //line-height: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  a {
    padding: 15px;
    padding-left: 5px;
    color: #44c4a1;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
  }
`;
const mapStateToProps = (state) => ({ companies: state.companies });
export default connect(mapStateToProps)(CompanyListing);
