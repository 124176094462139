import React, { Component } from "react";
import { Section } from "../../components/Modules"
import Logo from '../../components/Logo'
import { Link } from 'react-router-dom';

class ChangePasswordConfirmation extends Component {
  render() {
    return (
      <section className="section auth">
      <div className="container">
        <Section className="login">
          <Logo isLoading={true} />
            <h1>Change Password</h1>
            <p>Your password has been successfully updated. <Link to="/">Login Now!</Link></p>
           
          </Section>
          
        </div>
      </section>
    );
  }
}

export default ChangePasswordConfirmation;