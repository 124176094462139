import React, { Component } from "react";
import { connect } from 'react-redux';
import Logo from "../components/Logo";
import Button from "../components/Button";
import Skeleton from "react-loading-skeleton";

class Landing extends Component {
    state = {
        loading: false
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
            loading: true,
            });
        }, 2000);
    }    

    render() {
        const {state} = this.props
        let user = JSON.parse(localStorage.getItem('user'));
    
        console.log(state.userDetails)
        
        return (
            
            <div className="landingPage">
                <div className="container">
                    <Logo isLoading={this.state.loading} />
                    {state.userDetails && user && user.signInUserSession && user.signInUserSession.refreshToken.token ?  
                    
                    <> 
                    <h1>{this.state.loading ? "Welcome " + state.userDetails.name : <Skeleton width="400px" style={{ marginBottom: "20px" }} />}</h1>
                    <p>{this.state.loading ? "Click your profile icon below" : <Skeleton width="300px" />}</p>
                    <div className="avatar" onClick={()=>this.props.history.push('/dashboard')}>{this.state.loading ? <img src={state.userDetails.profile} alt=""/> : <Skeleton width="80px" height="80px" circle="true" />}</div>
                    </>
                   : 
                   <> <h1 style={{ marginBottom: "50px" }}>{this.state.loading ? "Login to Access Dashboard" : <Skeleton width="400px" style={{ marginBottom: "20px" }} />}</h1>
                    <Button title="Login to Dashboard" type="submit" styles="primary" handleClick={()=>this.props.history.push('/')} isLoading={this.state.loading} /> </>
                  }
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(Landing);