import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'

import sagas from './sagas';

const localStorageMiddleware = ({getState}) => { // <--- FOCUS HERE
  return (next) => (action) => {
      const result = next(action);
      const data = JSON.stringify(getState())
      console.log()
      localStorage.setItem('applicationState', JSON.stringify(getState()));
    //   localStorage.setItem('applicationState', JSON.stringify(
    //     {
    //       theme:JSON.parse(data).theme,
    //       userDetails:JSON.parse(data).userDetails
    //     }
    // ));
      return result;
  };
};


const reHydrateStore = () => { // <-- FOCUS HERE

  if (localStorage.getItem('applicationState') !== null) {
      return JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store

  }
}


const sagaMiddleware = createSagaMiddleware()
const store = createStore(
    rootReducer,
    reHydrateStore(),// <-- FOCUS HERE
    applyMiddleware(
        sagaMiddleware,
        localStorageMiddleware,// <-- FOCUS HERE 
    )
)

sagaMiddleware.run(sagas);

export default store;