import React, { Component } from "react";
import styled from "styled-components";
import tick from "../images/tick.svg";
import cross from "../images/cross.svg";
import { Datepicker } from "./Modules";

export class Backuptable extends Component {
  state = {
    data: [
      { file: "title=Weekly Update details=Weekly Site Software Updates", status: "FAIL", date: "12-22-2020", size: "20 mb" },
      { file: "cc", status: "SUCCESS", date: "12-22-2020", size: "20 mb" },
      { file: "wqwq", status: "FAIL", date: "12-22-2020", size: "20 mb" },
    ],
  };
  render() {
    const Backup = styled.div`
       {
        width: 78%;
        text-align: left;
        display: inline-block;

        h3 {
          color: #1d3557;
          font-size: 24px;
          text-align: left;
          display: inline-block;
        }
        th {
          color: #1d3557;
          font-size: 16px;
          width: 20%;
          padding: 20px;
          font-weight: 500;
          font-size: 14px;
        }

        td {
          border-top: 1px solid #ddd;
          padding: 20px;
          font-size: 14px;
          img {
            padding-right: 5px;
            height: 16px;
            width: 16px;
            position: relative;
            top: 2px;
          }
        }
        td:hover {
          background-color: #ddd;
        }
        table {
          border-style: solid;
          border-color: #d6d6d6;
          border-radius: 8px;
          border-width: thin;
        }
        tr {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
        .green {
          color: #44c4a1;
        }
        .red {
          color: #d40011;
        }
        .date {
          float: right;
          position: relative;
          top: 35px;
          .react-datepicker-wrapper {
            padding: 5px;
          }
        }
      }
    `;
    return (
      <Backup>
        <h3>Backups History</h3>
        <div className="date">
          Filter By Date:
          <Datepicker />
        </div>

        <table>
          <tr>
            <th>FILENAME</th>
            <th>DATE</th>
            <th>SIZE</th>
            <th>STATUS</th>
          </tr>
          {this.renderTableData()}
        </table>
      </Backup>
    );
  }
  renderTableData() {
    return this.state.data.map((data, index) => {
      const { file, date, size, status } = data; //destructuring
      return (
        <tr>
          <td style={{ width: "40%" }}>{file}</td>
          <td>{date}</td>
          <td>{size}</td>
          <td className={status === "SUCCESS" ? "green" : "red"}>
            <img src={status === "SUCCESS" ? tick : cross}  alt="" />
            {status}
          </td>
        </tr>
      );
    });
  }
}

export default Backuptable;
