import styled from 'styled-components'
import React from 'react';
import Skeleton from 'react-loading-skeleton';
export const Input = (props) => {
    function onChangeFunction(x) {
        props.onChange(x)
    }
    return(
        <InputField htmlFor={props.id} className={props.value && 'active'}>
            {props.isLoading ?
                <>
                   <span> {props.placeholder}</span>
                    <input  id={props.id} type={props.type} value={props.value} name={props.name}  onChange={(x)=> onChangeFunction(x)} autoFocus={props.focus}/>
                </>
            : <Skeleton count={2} />}
        </InputField>
    ) 
}
const InputField = styled.label`
    border: 1px solid #ccc;
    width: 100%;
    max-width:515px;
    min-height: 75px;
    font-size: 20px;
    padding: 0px 15px;
    box-sizing: border-box;
    outline: none;
    clear: both;
    display: block;
    margin:0 auto;
    text-align:left;
    position: relative;
    span{
        font-size: 20px;
        position: absolute;
        top: 23px;
        color: #04101E;
        opacity: 53%;
    }
    input{
        margin-top: 10px;
    }
    &:focus-within span, &.active span{
        font-size:14px;
        top:13px;
    }
    &:focus-within input, &.active input{
        margin-top:19px;
    }
`;