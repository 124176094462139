import React, { Component } from "react";
import Button from "./Button";
import Logo from "./Logo";
import HeaderButton from "./HeaderButton";
import prof_svg from "../images/editprofile.svg";
import logout from "../images/logout.svg";
import help from "../images/help.svg";
import bill from "../images/billing.svg";
import cardview from "../images/cardview.svg";
import listview from "../images/listview.svg";
import dashboard from "../images/dashboard.svg";
import global from "../images/global.svg";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import {default_profile} from "../images/icons";

export class Header extends Component {
  state = {
    loading: false,
    drop_down: false,
    redirect: false,
    view: "cardView",
    dropcontent:React.createRef(),
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: true,
      });
    }, 2000);
    // console.log(this.props,'this is props')
    this.cardView = this.cardView.bind(this);
    this.listView = this.listView.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.state.dropcontent.current && !this.state.dropcontent.current.contains(event.target)) {
      this.setState({
        drop_down: false,
      });
    }
  };

  cardView(e) {
    document.querySelector(".companyListing").classList.remove("listView");
    document.querySelector(".companyListing").classList.add("cardView");
    this.setState({ view: "cardView" });
  }
  listView(e) {
    document.querySelector(".companyListing").classList.remove("cardView");
    document.querySelector(".companyListing").classList.add("listView");
    this.setState({ view: "listView" });
  }
  menuToggle(e) {
    document.querySelector(".leftSidebar").classList.toggle("open");
    document.querySelector(".mobileToggle").classList.toggle("active");
  }

  render() {
    async function signOut() {
      try {
        await Auth.signOut();
        await localStorage.clear();
        window.location.href = "/";
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
    const details = JSON.parse(localStorage.getItem("applicationState"));
    const user = details.userDetails;
    const websiteUrl = details.websites && details.websites.website_url;
    return (
      <>
        <header className={this.props.isSidebar ? "sidebarHeader" : "noSidebar"}>
          {!this.props.removeLogo && (
            <div className="logo">
              <Logo width={225} height={100} isLoading={this.state.loading} />
            </div>
          )}
          <div className="headerBtns">
            <Button title="PHONE SUPPORT: +1 512 273 3906" type="button" styles="primary" isLoading={this.state.loading} />
            <Button title="Request Emergency Support" type="button" styles="secondary" isLoading={this.state.loading} />
          </div>
          {this.props.isSidebar ? (
            <div className="views">
              <HeaderButton handleClick={() => (window.location.href = "/dashboard")} title="Dashboard" type="button" styles="menu" icon={dashboard} isLoading={this.state.loading} />
              <HeaderButton handleClick={(e) => window.open(websiteUrl)} title="Website List" type="button" styles="menu" icon={global} isLoading={this.state.loading} />
              <span className="mobileToggle" onClick={(e) => this.menuToggle(e)}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
          ) : ((this.props.hideViewButton ? ( <div className="views"></div>):
            <div className="views">
              <HeaderButton handleClick={(e) => this.cardView(e)} title="card view" imgSrc="cardview" type="button" styles="menu" icon={cardview} isLoading={this.state.loading} />
              <HeaderButton handleClick={(e) => this.listView(e)} title="List view" imgSrc="listview" type="button" styles="menu" icon={listview} isLoading={this.state.loading} />
            </div>
          )
          )}

          {this.state.loading === false ? (
            <Skeleton circle="true" height="80px" width="80px" />
          ) : (
            <div className="dropdown" ref={this.state.dropcontent} onClick={() => this.setState({ drop_down: this.state.drop_down === true ? false : true })}>
              <div className="avatar">              
                {user.profile ? <img src={user.profile}  alt="" /> : default_profile}
              </div>
              <div className="dropdown-content" onClick={this.handleClickOutside} style={{ display: this.state.drop_down ? "block" : "none" }}>
                <div className="head">
                  <figure>
                  {user.profile ? <img src={user.profile}  alt="" /> : default_profile}
                  </figure>
                  <div className="prof">
                    <strong>{this.state.name ? this.state.name : "John Doe"}</strong>
                    <a href="#">View Profile</a>
                  </div>
                </div>
                <div className="links">
                  <a href="/register">
                    <img src={prof_svg} alt="Edit Profile" />
                    Edit Profile
                  </a>
                  <a href="#">
                    <img src={bill} alt="Billing" />
                    Billing
                  </a>
                  <a href="/register">
                    <img src={help} alt="Help" />
                    Help
                  </a>
                  <a onClick={() => signOut()}>
                    <img src={logout} alt="Logout" />
                    Logout
                  </a>
                </div>
              </div>
            </div>
          )}
        </header>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ user: state.userDetails });
export default connect(mapStateToProps)(Header);
