import { RECEIVE_GENERALSETTINGS, RECEIVE_COMPANYLIST, RECEIVE_USER, RECEIVE_WEBSITELIST, RECEIVE_UPTIME_MONITOR, RECEIVE_MONGOS,RECEIVE_SSL,RECEIVE_TICKETS } from './action'
const initalState = {
  theme:'',
  userDetails:"",
  companies:{ payload:[] },
  websites: { payload:[] },
  uptime: {},
  ssl: {},
  mongos: {},
  isLoader: false
}
const reduce = (state=initalState, action) => {
  switch(action.type){
    case RECEIVE_GENERALSETTINGS: 
     return {
        ...state,
        theme:action.payload[0],
        isLoader: action.payload[0] ? true:false
      }
     case RECEIVE_COMPANYLIST: 
        return {
          ...state,
          companies: {
            ...state.companies,
            payload: action.payload.filter(itm => itm.type === 'client')}
        }
      case RECEIVE_USER: 
       return {
          ...state,
          userDetails:action.payload[0]
       }
       case RECEIVE_WEBSITELIST: 
        return {
          ...state,
          websites:action.payload[0]
        }
        case RECEIVE_UPTIME_MONITOR: 
         return {
           ...state,
           uptime:action.payload
         }
         case RECEIVE_MONGOS: 
          return {
            ...state,
            mongos:action.payload
          }
        case RECEIVE_TICKETS: 
          return {
            ...state,
            websites:action.payload[0]
          }
          case RECEIVE_SSL: 
           return {
             ...state,
             ssl:action.payload
           }
           
          
         
    default:
      return state
  }
}
export default reduce;