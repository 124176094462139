import React, { Component } from "react";
import styled from "styled-components";
import tick from "../images/tick.svg";
import cross from "../images/cross.svg";
import warning from "../images/warning.svg";
import info from "../images/info.svg";
import droparrow from "../images/droparrow.svg";

export class Alertbox extends Component {
  state = {
    cond: true,
    drop: true,
  };
  render() {
    const Alert = styled.div`
       {
        text-align: left;
        font-size: 16px;
        display: inline-block;
        width: 78%;

        img {
          padding-right: 5px;
          position: relative;
          top: 1px;
        }

        .details {
          font-size: 16px;
          color: #828282;
          h3 {
            font-size: 18px;
          }
        }

        .danger-content {
          border-style: solid;
          border-color: #ffcbcb;
          border-radius: 10px;
          border-width: thin;
          background: #f8d7da;
          color: #e63946;
          margin: 15px 0;
          padding: 20px;
          padding-top: 5px;
          box-shadow: 2px 2px #dddddd;
          h3 {
            font-size: 18px;
          }
          .danger-details {
            font-size: 16px;
          }
        }

        .success-content {
          border-style: solid;
          border-color: #abffe8;
          border-radius: 10px;
          border-width: thin;
          background: #d4edda;
          color: #007728;
          margin: 15px 0;
          padding: 20px;
          padding-top: 5px;
          box-shadow: 2px 2px #dddddd;
          h3 {
            font-size: 18px;
          }
          .success-details {
            font-size: 16px;
          }
        }

        .warning-content {
          border-style: solid;
          border-color: #ffeeba;
          border-radius: 10px;
          border-width: thin;
          background: #fff3cd;
          color: #a59707; //#856404;
          margin: 15px 0;
          padding: 20px;
          padding-top: 5px;
          box-shadow: 2px 2px #dddddd;
          h3 {
            font-size: 18px;
          }
          .warning-details {
            font-size: 16px;
          }
        }
        .info-content {
          border-style: solid;
          border-color: #ffeeba;
          border-radius: 10px;
          border-width: thin;
          background: #d1ecf1;
          color: #005765;
          margin: 15px 0;
          padding: 5px 20px;
          //padding-top: 5px;
          box-shadow: 2px 2px #dddddd;

          -webkit-transition: all 200ms ease;
          -moz-transition: all 200ms ease;
          -ms-transition: all 200ms ease;
          -o-transition: all 200ms ease;
          transition: height 200ms ease;

          h3 {
            font-size: 18px;
          }
          .info-details {
            font-size: 16px;
            padding: 10px 0;
            transition: height 200ms ease;
          }
          .info-details-collapsed {
            height: 0;
            overflow: hidden;
            padding: 0 !important;
            transition: height 200ms ease;
          }
        }
      }
    `;

    return (
      <Alert>
        <div>{this.Switchclass(this.props.type)}</div>
      </Alert>
    );
  }
  Switchclass(params) {
    switch (params) {
      case "success-content":
        return (
          <div className="success-content">
            <h3>
              <img src={tick} alt="success" />
              {this.props.heading}
            </h3>
            <div className="success-details">{this.props.details}</div>
          </div>
        );

      case "danger-content":
        return (
          <div className="danger-content">
            <h3>
              <img src={cross} alt="cross" />
              {this.props.heading}
            </h3>
            <div className="danger-details">{this.props.details}</div>
          </div>
        );

      case "info-content":
        return (
          <div className="info-content">
            <h3>
              <img src={info} alt="info" />
              {this.props.heading}
              <img onClick={() => this.setState({ drop: this.state.drop ? false : true })} src={droparrow} alt="drop" style={{ float: "right", transform: "rotate(180deg)" }} />
            </h3>
            <div className="info-details" style={{ height: this.state.drop === true ? "100px" : "0px", transition: this.state.drop === true ? "all 0.3s" : "all 0.3s" }}>
              {this.props.details}
            </div>
          </div>
        );

      case "warning-content":
        return (
          <div className="warning-content">
            <h3>
              <img src={warning} alt="warning" />
              {this.props.heading}
            </h3>
            <div className="warning-details">{this.props.details}</div>
          </div>
        );
      default:
        return (
          <div className="details">
            <h3>{this.props.heading}</h3>
            {this.props.details}
          </div>
        );
    }
  }
}

export default Alertbox;
