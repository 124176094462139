import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

class HeaderButton extends Component {
  render() {
    const localStorageData = JSON.parse(localStorage.getItem('applicationState')) 
    return this.props.isLoading ? (
      <ButtonField
        className={this.props.className}
        padding={this.props.padding}
        type={this.props.type}
        buttonType={this.props.styles}
        buttonStyle={this.props.themeStyling ? this.props.themeStyling.theme && this.props.themeStyling.theme : localStorageData && localStorageData.theme}
        onClick={this.props.handleClick}
      >
        <img src={this.props.icon}  alt="" />
        <span className="title">{this.props.title}</span>
      </ButtonField>
    ) : (
      <StyleSkeleton>
        <Skeleton width={80} height={25} />
      </StyleSkeleton>
    );
  }
}
const ButtonField = styled.button`
  border: 0px;
  font-size: 12px;
  font-weight: 300; //NEW from 600
  text-transform: uppercase;
  padding: ${({ padding }) => (padding ? padding : "5px 10px")}; //old: 20,30
  border-radius: 10px;
  color: #fff;
  outline: none;
  cursor: pointer;
  margin: 0px 5px; //NEW
  display: inline-flex;
  line-height: 23px;
  position: relative;
  opacity:0.7;

  &:focus{
    opacity:1;
  }

  ${({ buttonType, buttonStyle }) =>
    buttonType === "primary" &&
    `
        background: rgb(29,53,87);
        background: ${buttonStyle ? buttonStyle.primary: 'rgb(29,53,87)' };
  `}
  ${({ buttonType, buttonStyle }) =>
    buttonType === "secondary" &&
    `
        background: rgb(230,57,70);
        background:${buttonStyle ? buttonStyle.secondary: 'rgb(230,57,70)'};
    `}
  ${({ buttonType, buttonStyle }) =>
    buttonType === "menu" &&
    `
        background: white;
        color: black;
        background: ${buttonStyle &&   buttonStyle.menu ? buttonStyle.menu : 'white'};
        text-alignment=center;
        &:hover{
          background:#f7f7f7;
        }
        &:focus{
          background:#f7f7f7;
        }
        img{
          padding:5px 8px 0px 0px;
          bottom:3px;
          position:relative;
        }
      @media (max-width: 767px) {
        span.title{
          display:none
        }
      }
        
  `}
`;

const StyleSkeleton = styled.div`
  padding: 5px 10px;
  margin: 0 auto;
  display: inline-flex;
  //bottom: 35px;
  position: relative;
  top: 20px;
  margin: 0px 5px; //NEW
  span {
    border-radius: 10px;
  }
`;

const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(HeaderButton);
