import React, { Component } from 'react';
import Validate from "../../components/auth/FormValidation";
import { Section,  Input,  Form } from "../../components/Modules"
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Loader } from "../../images/icons";


class Register extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    compnayNames: '',
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    },
    loading:false,
    loader:  true
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    const { username, email, password } = this.state;

    try{

      await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          "custom:company": window.location.host,
        }
      });
      this.props.history.push('/confirmsignup');
    }catch(error){
      let err = null;
      !error.message ? err = {"message": error} : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
    // AWS Cognito integration here
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    //document.getElementById(event.target.id).classList.remove("is-danger");
  }
  onSelectChange = ( event,name ) => {
    const {options, selectedIndex} = event.target;
    this.setState({
      [name]:options[selectedIndex].innerHTML
    })
    console.log(this.state)
  }
 
  componentDidMount(){
    var data = JSON.parse(localStorage.getItem('applicationState'));  
    data.theme &&
    setTimeout(()=>{
      this.setState({
        loader:false
      })
    },1000)
  
  }

  login = event => {
      this.props.history.push('/')
  }
  render() {
    return (
      <section className="section auth">
        <div className="container">
        <Section className="login">
            <Logo isLoading={this.state.loading}/>
            <h1>Register to Access Deshboard</h1>
            <Form>
              <form onSubmit={this.handleSubmit}>
                  <fieldset>
                    <Input type="text" id="username"  name="username" value={this.state.username} placeholder="Enter username." onChange={this.onInputChange} isLoading={true} focus={true}/>
                    <Input type="email" id="email"  name="email" value={this.state.email} placeholder="Enter email." onChange={this.onInputChange} isLoading={true}/>
                    <Input type="password" id="password" name="password" value={this.state.password} placeholder="Password" onChange={this.onInputChange} isLoading={true}/>
                    <Input  className="input"  type="password" id="confirmpassword" placeholder="Confirm password" value={this.state.confirmpassword} onChange={this.onInputChange} isLoading={true}/>
                  </fieldset>
                  <div className="multiBtn">
                    <Button className="single" title="Signup" type="submit" styles="primary" isLoading={true} />
                  </div>
                  <p>Do you wanna login? <Link to="/">Login Now!</Link></p>
              </form>
            </Form>
                {this.state.loader && <div className="loader">{Loader}</div>}
          </Section>
        </div>
   
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(Register);
