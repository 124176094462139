import React, { Component } from "react";
import { Section, Header} from "../../components/Modules";
import Moment from 'react-moment';
import { connect } from "react-redux";
import { requestWebsiteList,requestUptimeMonitor, requestSsl } from '../../store/action'
import Card from '../../components/Card'
import Button from '../../components/Button'
import report from "../../images/report.svg";
import pdf from "../../images/pdf.svg";
import download from "../../images/download.svg";
import Skeleton from "react-loading-skeleton";
import "react-datepicker/dist/react-datepicker.css";
import {Firewall, Plugin, Theme, BLink} from "../../images/icons";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: "blackdot-20201020-075207-x78du3.zip",
      backuptime: "Nov 03, 2020 12:32",
      red: true,
      arr: new Date(new Date().setDate(new Date().getDate() - 31)).setHours(0, 0, 0, 0),
      arr1: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0),
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: true,
      });
    }, 2000);
    this.props.dispatch(requestWebsiteList(this.props.match.params.id.replace("=","")));
    this.props.websites.uptime && 
    this.props.dispatch(requestUptimeMonitor(this.props.websites.name)); 
    this.props.websites.ssl && 
    this.props.dispatch(requestSsl(this.props.websites.website_url)); 
  }
  
  render() {
  

    function Skeletonstyle() {
      return (
        <div className="itemCard">
          <div className="header">
            <Skeleton height="160px" />
          </div>
          <div className="description">
            <h3>
              <Skeleton width="100%" height="30px" />
            </h3>
            <p>
              <Skeleton width="100%" height="20px" />
            </p>
            <span className="listing">
              <Skeleton width="100%" height="20px" />
            </span>
          </div>
        </div>
      );
    }

    const { websites } = this.props;
    const { uptime } = this.props;

    return (
      <section className="section auth">
        <div className="container">
          <Section width={"95%"} padding={"25px"}>
            <Header hideViewButton={true}/>
            <br />
            <br />
            <div className="mb_display" style={{ display: "flex" }}>
              {websites && websites.report && this.state.loading ? (
                <>
                  {/* <Card icon={calender} title="Weekly Updates" description="Weekly Site Software Updates" viewLink="/single_page/weekly" styles="primary">
                <div>
                  <ul className="updateList">
                    <li id="cross">
                      2 Plugins are not updated
                      <img alt="" style={{ float: "right" }} src={cross} />
                      <ul>
                        <li>Elementor (ver 3.1.2)</li>
                        <li>All in one migration (ver 5.3.1)</li>
                      </ul>
                    </li>

                    <li>
                      WordPress updated <img alt="" style={{ float: "right" }} src={tick} />
                    </li>
                    <li>
                      All Themes are updated
                      <img alt="" style={{ float: "right" }} src={tick} />
                    </li>
                  </ul>
                </div>
              </Card> */}
                  {/* <Card icon={database} title="Backups" description="Site Files & Database Backups" viewLink="#" styles="primary">
                <div>
                  <img alt="" src={gdrive} style={{ float: "left", width: "60px", height: "55px", position: "relative", right: "5px" }} />
                  <ul className="backupList">
                    <li style={{ fontSize: "15px" }}>Latest Backups</li>
                    <li style={{ color: "gray" }}>{this.state.filename}</li>
                    <li>{this.state.backuptime}</li>
                  </ul>
                </div>
                <table className="tablelist">
                  <tr>
                    <td>filename-3112020.zip</td>
                    <td>Nov 03, 2020 12:32</td>
                  </tr>
                  <tr>
                    <td>filename-3112020.zip</td>
                    <td>Nov 03, 2020 12:32</td>
                  </tr>
                  <tr>
                    <td>filename-3112020.zip</td>
                    <td>Nov 03, 2020 12:32</td>
                  </tr>
                </table>
              </Card> */}
                  {/*console.log(websites, "websites")*/}
                  {/* {console.log(uptime)} */}
                  
                  <Card icon={report} title="Monthly Reporting" description="Monthly Website Reporting" viewLink="/single_page/report" styles="primary" textcolor="#fff">
                    <div>
                      <img alt="" src={pdf} style={{ width: "100%", height: "95px", marginBottom: "5px" }} />
                      <ul className="backupList" style={{ textAlign: "center" }}>
                        <li style={{ fontSize: "14px" }}>Latest Monthly Report</li>
                        <li style={{ color: "gray", fontSize: "9px" }}>
                          <Moment format="MMM DD, YYYY HH:mm">{websites.report[websites.report.length - 1].list.length > 0 ? websites.report[websites.report.length - 1].list[websites.report[websites.report.length - 1].list.length - 1].date : "Null"}</Moment>
                        </li>
                        <li style={{ color: "#e63946", fontSize: "13px", borderTop: "1px solid #ccc" }}>
                          {websites.report[websites.report.length - 1].list.length > 0 ?
                          <a href={websites.report[websites.report.length - 1].list[websites.report[websites.report.length - 1].list.length - 1].fileUrl} target="_blank" rel="noreferrer">
                            DOWNLOAD REPORT <img alt="" src={download} style={{ position: "relative", top: "5px" }} />
                          </a>
                          : 'Null'}
                        </li>
                      </ul>
                    </div>

                    {/* <table className="tablelist">
                  {websites.report.map((rp,ind)=> 
                    <tr key={ind}>
                      <td><Moment format="MMMM">{rp.date}</Moment></td>
                      <td><Moment format="DD - YYYY - HH:mm">{rp.date}</Moment></td>
                      <td>
                        <a target="_blank" href={rp.fileUrl}>
                          Download <img alt="" src={download} width="8" height="8" style={{ padding: "0px 5px" }} />
                        </a>
                      </td>
                    </tr>
                  )}
                </table> */}
              </Card>
             
              {/* <Card icon={perfopt} title="Perform-ance Optimization" description="Optimize Performance" viewLink="#" styles="primary"></Card> */}
             
              <Card title="Firewall" description="Cloud Proxy Firewall" viewLink="#" styles="tertiary" textcolor="#1D3557" viewLink="/single_page/firewall">
                <div className="firewallMain">
                  {Firewall}
                  <Button 
                    title={this.props.websites && this.props.websites.ssl === true ? "enabled" : "disabled"}
                    styles={this.props.websites && this.props.websites.ssl === true ? "firewallenabled" : "firewalldisabled"}
                    >
                  </Button>
                </div>
              </Card>

              <Card title="Plugins" description="Plugins Update Status" styles="tertiary" textcolor="#1D3557" viewLink="/single_page/plugins">
                <div className="firewallMain">
                  {Plugin}
                  <Button 
                    title={this.props.websites && this.props.websites.plugin === 0 ? "All Updated" : this.props.websites.plugin +" Not Updated"}
                    styles={this.props.websites && this.props.websites.plugin === 0 ? "firewallenabled" : "firewalldisabled"}
                    >
                  </Button>
                </div>
              </Card>

              <Card title="Theme" description="Theme Update Status" styles="tertiary" textcolor="#1D3557" viewLink="/single_page/theme">
                <div className="firewallMain">
                  {Theme}
                  <Button 
                    title={this.props.websites && this.props.websites.theme === true ? "Updated" : "Not Updated"}
                    styles={this.props.websites && this.props.websites.theme === true ? "firewallenabled" : "firewalldisabled"}
                    >
                  </Button>
                </div>
              </Card>

              <Card title="Broken Links" description="Broken Links" styles="tertiary" textcolor="#1D3557" viewLink="/single_page/theme">
                <div className="firewallMain">
                  {BLink}
                  <Button 
                    title={this.props.websites && this.props.websites.brokenlinks === 0 ? "No Broken Links" : this.props.websites.brokenlinks+" Links Found"}
                    styles={this.props.websites && this.props.websites.brokenlinks === 0 ? "firewallenabled" : "firewalldisabled"}
                    >
                  </Button>
                </div>
              </Card>
             
                {uptime.monitors &&
                    uptime.monitors.map((subarray,index) => (
                      
                      <Card icon={report} title={ subarray.type === 1 ? "Uptime Monitoring" : subarray.type === 2 ? "Keyword Monitoring" : subarray.type === 3 ? "Ping Monitoring" : subarray.type === 4 ? "Port Monitoring" : "Uptime Monitoring"} type={ subarray.type === 1 ? "HTTP(s)" : subarray.type === 2 ? "Keyword" : subarray.type === 3 ? "Ping" : subarray.type === 4 ? "Port" : ""} description={subarray.friendly_name} viewLink="/single_page/monitoring/" id={subarray.id} styles="primary" key={index} >
                        <ul className="uptimeMonitoringWidget">
                          {subarray.custom_uptime_ratio.split("-").map((uptimes,key) => {
                          return <> 
                            {key === 0 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 24 hours</p></li>}
                            {key === 1 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 7 days</p></li>}
                            {key === 2 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 30 days</p></li>}
                            {key === 3 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 90 days</p></li>}
                         </>
                          })}
                        </ul>
                        
                      </Card>
                    ))
                  }
                </>
              ) : (
                <>
                  <Skeletonstyle />
                  <Skeletonstyle />
                  <Skeletonstyle />
                  <Skeletonstyle />
                  <Skeletonstyle />
                </>
              )}
            </div>
          </Section>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ websites: state.websites, uptime: state.uptime, userDetails: state.userDetails});

export default connect(mapStateToProps)(Dashboard);
