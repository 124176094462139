import styled from 'styled-components'

export const Form = (props) => {
    return <FormField> {props.children} </FormField>
}
const FormField = styled.div`
    label{
        border:1px solid #808080;
        border-top:0px;
        padding-top:14px;
        font-size:14px;
        color:#77869A;
        input{
            width: 100%;
            border: 0;
            outline: none;
            font-size: 20px;
            color: #04101E;
        }
        &:first-child{
            border-top-right-radius:10px;
            border-top-left-radius:10px;
            border-top:1px solid #808080;
        }
        &:last-child{
            border-bottom-right-radius:10px;
            border-bottom-left-radius:10px;
        }
        span{
            -webkit-transition: all 200ms ease-out;
            -moz-transition: all 200ms ease-out;
            -ms-transition: all 200ms ease-out;
            -o-transition: all 200ms ease-out;
            transition: all 200ms ease-out;
        }

    }
    label input{
        display:block; 
    }

    button{
        max-width:240px;
        margin:0px;
        width:100%
    }
    p{
        font-size:16px;
        color:#5E5E5E;
        letter-spacing: 1.2px;
        margin:55px 0 0;
        line-height: normal;
    }
    a{
        color:#434343;
        font-weight:600;
        text-decoration:none;
    }

    @media (max-width:767px){
        button{
            max-width:100%;
            margin-bottom:15px
        }
        p{
            margin:45px 0 0;
        }
    }
`;