import React, { Component } from 'react';
import FormErrors from "./formError/formErrors";
import Validate from "../../components/auth/FormValidation";
import { Auth } from 'aws-amplify';
import { Section,  Input,  Form } from "../../components/Modules"
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import { Link } from 'react-router-dom';

class ChangePassword extends Component {
  state = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try{
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      await Auth.changePassword(user, this.state.oldpassword, this.state.newpassword);
      this.props.history.push('/changepasswordconfirmation');
    }catch(error){
      console.log(error);
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <Section className="login">
            <Logo />
            <h1>Change Password</h1>
            <FormErrors formerrors={this.state.errors} />
            <Form>
              <form onSubmit={this.handleSubmit}>
                      <fieldset>
                          <Input type="text" id="oldpassword"  name="oldpassword" value={this.state.oldpassword} placeholder="Old password" onChange={this.onInputChange}/>
                          <Input type="text" id="newpassword"  name="newpassword" value={this.state.newpassword} placeholder="New password" onChange={this.onInputChange}/>
                          <Input type="text" id="confirmpassword"  name="confirmpassword" value={this.state.confirmpassword} placeholder="Confirm password" onChange={this.onInputChange}/>
                      </fieldset>
                      <div className="multiBtn">                       
                        <Button className="single" title="Change Password" type="submit" styles="primary" />
                      </div>
                  <p>Do you wanna login? <Link to="/">Login Now!</Link></p>
              </form>
            </Form>
          </Section>
          
        </div>
      </section>
    );
  }
}

export default ChangePassword;