import React, { Component } from "react";
import styled from "styled-components";
// import Skeleton from "react-loading-skeleton";
// import calender from "../images/calender.svg";
export class PageHeads extends Component {
  state = {};

  render() {
    //console.log(this.props);
    const ProgressCs = (props) => {
      console.log(props.percentvalue);
      if (this.props.percentvalue) {
        return (
          <progress value={this.props.percentvalue} max="100">
            {this.props.percentvalue}
          </progress>
        );
      }
    };
    const Pageheading = styled.div`
      display: flex;
      align-items: center;
      font-family: 'Montserrat';
      border-bottom:1px solid #D8D8D8;
      padding:60px 0 25px 0;
      margin-bottom: 10px;

      h1{
        font-size: 34px;
        font-weight: 600;
        color: #1D3557;
        margin: 0;
        text-align: left;
      }

      small{
        color: #A7A7A7;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        /*padding-left: 10px;*/
      }

      a{
        font-size: 16px;
        color: #1D3557;
        text-decoration: none;
        display: block;
        border:1px solid #CBCBCB;
        border-radius: 100px;
        padding: 8px 20px;
        margin-top: 10px;
        margin-left: auto;
        &:hover{
          background: #f1f1f1;
        }
        b{
          font-weight: 500;
        }
      }

      progress {
        display: inline-block;
        width: 130px;
        height: 12px;
        padding: 0;
        margin-left: auto;
	      margin-right: 20px;
        background: none;
        border: 2px;
        border-radius: 50px;
        position: relative;
        font-size: 11px;
        color: #808080;
        font-weight: bold;
        margin-top: 27px;
        &::-webkit-progress-bar {
          background-color: #dbdbdb;
          border-radius: 7px;
        }
        &::-webkit-progress-value {
          background-color: #e63946;
          border-radius: 7px;
        }
        &:before {
          margin: -18px 0 0 2px;
          padding: 0;
          display: inline-block;
          float: left;
          content: "Status";
        }
        &:after {
          margin: -31px 0 0 -2px;
          padding: 0;
          display: inline-block;
          float: right;
          content: attr(value) "%";
        }
      }

      @media (max-width:1140px){
        small{display: block; padding-left: 0;}
      }
      @media (max-width:980px){
        flex-wrap:wrap;
        h1{width: 74%;}
        small{width: 100%; display: block; padding-left: 0;}
        progress{width: 100%; margin-top: 10px; margin-bottom: 10px;}
        a{width: 100%; text-align: center;}
      }      
    `;
    const Icon = styled.div`
      margin-right: 20px;
      svg{
        width: 65px;
        height: 65px;
        path{
          fill: #1D3557;
        }
      }
    `;

    return (
      <Pageheading>
        <Icon>{this.props.icon}</Icon>
        <h1>{this.props.title}<br/> <small>{this.props.details}</small></h1>
        {this.props.percentvalue && <ProgressCs />}
        <a href={this.props.weblink} target="_blank" rel="noreferrer"><b>Website: </b>{this.props.weblink}</a>
      </Pageheading>	
    );
  }
}

export default PageHeads;
