import { Link } from "react-router-dom";
import styled from "styled-components";
import arrow from "../images/arrow.svg";
import React, { Component } from "react";
import { connect } from "react-redux";

class Card extends Component {
  render() {
    if (this.props.title && this.props.description) {
      return (
        <CardWrapper>
          <Header theme={this.props.state && this.props.state.theme} styles={this.props.styles}>
            <Icon src={this.props.icon}></Icon>
            <Title render={this.props.title} description={this.props.description} type={this.props.type} color={this.props.textcolor}/>
          </Header>
          <Aside>{this.props.children}</Aside>
          <Footer>
            <Link to={{pathname:this.props.viewLink, query:this.props.id}}>
              View Details <img src={arrow} style={{ marginLeft: "10px" }}  alt="" />
            </Link>
          </Footer>
        </CardWrapper>
      );
    } else {
      return <CardWrapperDisable></CardWrapperDisable>;
    }
  }
}

const Title = (props) => {
  return (
    <TitleWrap color={props.color}>
      {props.render} <span>{props.description} {props.type && ' - ' +props.type }</span>
    </TitleWrap>
  );
};
const CardWrapper = styled.div`
  width: 18%;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  margin: 1%;
  box-shadow: 0px 0px 2px #717171;
  position: relative;
  
  @media (min-width:1024px) and (max-width:1366px){
    width: calc(24% - 13px);
    float: left;
    min-height: 350px;
  }
  @media (max-width: 1024px) {
    width: 48%;
    float: left;
    min-height: 350px;
  }
  @media (max-width: 767px) {
    width: 97%;
    margin:10px auto 20px;
    float: none;
  }
`;
const CardWrapperDisable = styled.div`
  width: 18%;
  height: 100px;
  background: #d5d5d5;
  margin: 1%;
  border-radius: 10px;
  min-height: 284px;
  box-shadow: 0px 0px 2px #717171;
`;

const Header = styled.div`
  padding: 15px;
  border-bottom:3px solid #000;
  ${({ theme, styles }) =>
    styles === "primary" &&
    `
        background: rgb(29,53,87);
        background: ${theme.primary};
    `}
  ${({ theme, styles }) =>
    styles === "secondary" &&
    `
        background: rgb(230,57,70);
        background:${theme.secondary};
    `}
    ${({ theme, styles }) =>
    styles === "tertiary" &&
    `
        text-align:center;
        
        background: rgb(255,255,255);
        background:${theme.tertiary};
    `}
`;
const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  text-align: right;
  width: 100%;
  line-height: 10px;
  box-sizing: border-box;
  a {
    color: #e63946;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
  }
`;
const Aside = styled.div`
  padding: 15px;
  height: 200px; //119
  font-size: 14px;
  overflow: hidden;
`;
const Icon = styled.img`
  float: left;
  padding-right: 10px;
`;
const TitleWrap = styled.div`
  ${({ color })=>`{
    color:${color};
  }`}

  font-size: 24px;
  font-weight: 500;
  line-height: 23px;
  span {
    display: block;
    font-size: 12px;
    font-weight: normal;
    color: #a7a7a7;
  }
  
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(Card);
