import React, { Component } from "react";
import { Header, SectionView, PageHeads, MonthlyReport, Monitoring, Ticket } from "../../components/Modules";
import Logo from "../../components/Logo";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import { Report } from "../../images/icons";

class View extends Component {
  state = {
    sidebar: [
      { url: "report", title: "Monthly Reporting", details: "Monthly website reporting" },
      { url: "weekly", title: "Weekly Updates", details: "Weekly website updates" },
      { url: "backup", title: "Site & Database Backups", details: "Database website backups" },
      { url: "optimization", title: "Performance Optimization", details: "Performance Optimization" },
      { url: "firewall", title: "Firewall Settings", details: "Firewall Settings" },
      { url: "performance", title: "Performance Test", details: "Performance Test" },
      { url: "monitoring", title: "Uptime Monitoring", details: "Uptime Monitoring" },
      { url: "comments", title: "Spam Comments", details: "Spam comments" },
      { url: "plugins", title: "Plugins Update Status", details: "Plugins Update Status" },
      { url: "theme", title: "Theme Update Status", details: "Theme Update Status" },
      { url: "ticket", title: "Tickets", details: "" },
    ],
  };
  
  componentDidMount() {
  }

  render() {
    const { websites, match } = this.props;
    return (
      <section className="section auth">
        <SectionView width={"95%"} padding={"0px"}>
          <div className="container">
            <div className="leftSidebar">
              <div className="sidebarLogo">
                <Logo isLoading={true} />
              </div>
              <Sidebar data={match.params.page} onClickHandler={(e) => this.pageRender(e)} />
            </div>
            <div className="contentArea">
              <Header removeLogo={false} isSidebar={true} />
              {this.state.sidebar.map(
                (item) =>
                  match.params.page === item.url && (
                    <>
                      <PageHeads title={item.title} details={item.details} icon={Report} weblink={websites.website_url} />
                      <div className="reportWrapper">
                        {console.log(this.props)}
                        {/* {match.params.page === "monitoring" ? <Monitoring /> : <h2>Page not Found</h2>} */}
                        {match.params.page === "report" ? <MonthlyReport data={websites.report} /> 
                        : match.params.page === "monitoring" && this.props.websites.uptime ? <Monitoring  data={this.props} /> 
                        : match.params.page === "ticket" ? <Ticket data={websites.ticket}/> 
                        : <h2>Page not Found</h2>}
                      </div>
                    </>
                  )
              )}
              {this.state.sidebar.includes(match.params.page) && <h1>Url not found</h1>}
            </div>
          </div>
        </SectionView>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ websites: state.websites, uptime: state.uptime });
export default connect(mapStateToProps)(View);
