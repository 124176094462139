import React, { Component } from 'react';
import { Section,  Header } from "../../components/Modules"

class Loading extends Component {
    constructor(props){
        super(props);
        this.state={
          search:''
        };
    }

    render(){
     

        return (
            <section className="section auth">
                <div className="container" style={{height:'400px'}}>
                    <Section width={'90%'} padding={'40px'}>
                       <Header /> 
                        <h1>Loading...</h1>
                    </Section>
                </div>
            </section>
        )
    }
}
export default Loading