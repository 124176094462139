import React, { Component } from "react";
import Validate from "../../components/auth/FormValidation";
import FormErrors from "./formError/formErrors";
import { Link } from "react-router-dom";
import { Section, Input, Form } from "../../components/Modules";
import Button from "../../components/Button";
import Logo from "../../components/Logo";
import { Auth } from "aws-amplify";
// import jwt_decode from 'jwt-decode';
import Skeleton from "react-loading-skeleton";
import { requestUser } from "../../store/action";
import { connect } from "react-redux";
import { Loader } from "../../images/icons";



class Login extends Component {
  state = {
    username: "",
    password: "",
    company: "",
    errors: {
      cognito: null,
      blankfield: false,
    },
    loader:  true
  };
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };
 
  componentDidMount() {
    // console.log(this.props,'this is props')
    var data = JSON.parse(localStorage.getItem('applicationState'));  
    data.isLoader &&
    setTimeout(()=>{
      this.setState({
        loader:false
      })
    },2000)
  }
  componentWillReceiveProps(props){
    console.log('run After', props)
    props.state.isLoader &&
    setTimeout(()=>{
      this.setState({
        loader:false
      })
    },2000)
  }

  handleSubmit = async (event) => {
    event.preventDefault();
      this.setState({
        loader: true,
      });
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
        loader: false,
      });
    }
    try {
      
      const user = await Auth.signIn(this.state.username, this.state.password, this.state.company);
      // let token = user.signInUserSession.idToken.jwtToken;
      // let decoded = jwt_decode(token);
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      this.props.dispatch(requestUser());

      this.props.history.push("/dashboard");

    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err,
        },
        loader: false,
      });
    }
  };
  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    //document.getElementById(event.target.id).classList.remove("is-danger");
  };
  register = (event) => {
    this.props.history.push("/register");
  };

  
  render() {
    return (
      <section className="section auth">
        <div className="container">
          <Section className="login">
            <Logo isLoading={this.props.state.theme} />
            <h1>{this.props.state.theme ? "Login to Access Dashboard" : <Skeleton width="300px" style={{ marginBottom: "20px" }} />}</h1>
            <FormErrors formerrors={this.state.errors} />

            <Form>
              <form onSubmit={this.handleSubmit}>
                <fieldset>
                  <Input type="text" id="username" name="username" value={this.state.username} placeholder="Username / Email Adress" onChange={this.onInputChange} isLoading={this.props.state.theme} focus={true}/>
                  <Input type="password" id="password" name="password" value={this.state.password} placeholder="Password" onChange={this.onInputChange} isLoading={this.props.state.theme} />
                </fieldset>
                <div className="multiBtn">
                  <Button title="Login" type="submit" styles="primary" isLoading={this.props.state.theme} />
                  <Button title="Create Account" type="button" styles="secondary" handleClick={this.register} isLoading={this.props.state.theme} />
                </div>
                {this.props.state.theme ? (
                  <p>
                    Forgotten your login details? <Link to="/forgotpassword">Get Help Signing In</Link>
                  </p>
                ) : (
                  <Skeleton width="70%" />
                )}
                {this.state.loader && <div className="loader">{Loader}</div>}
              </form>
            </Form>
          </Section>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ state });
export default connect(mapStateToProps)(Login);
