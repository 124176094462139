export const REQUEST_GENERALSETTINGS = "REQUEST_GENERALSETTINGS";
export const RECEIVE_GENERALSETTINGS = "RECEIVE_GENERALSETTINGS";

export const REQUEST_COMPANYLIST = "REQUEST_COMPANYLIST";
export const RECEIVE_COMPANYLIST = "RECEIVE_COMPANYLIST";

export const REQUEST_WEBSITELIST = "REQUEST_WEBSITELIST";
export const RECEIVE_WEBSITELIST = "RECEIVE_WEBSITELIST";

export const REQUEST_USER = "REQUEST_USER";
export const RECEIVE_USER = "RECEIVE_USER";

export const REQUEST_UPTIME_MONITOR = "REQUEST_UPTIME_MONITOR";
export const RECEIVE_UPTIME_MONITOR = "RECEIVE_UPTIME_MONITOR";

export const REQUEST_MONGOS = "REQUEST_MONGOS";
export const RECEIVE_MONGOS = "RECEIVE_MONGOS";


export const REQUEST_REPORT = "REQUEST_REPORT";
export const RECEIVE_REPORT = "RECEIVE_REPORT";

export const REQUEST_SSL = "REQUEST_SSL";
export const RECEIVE_SSL = "RECEIVE_SSL";

export const REQUEST_TICKETS = "REQUEST_TICKETS";
export const RECEIVE_TICKETS = "RECEIVE_TICKETS";

export const requestGeneralSettings = () => ({ type: REQUEST_GENERALSETTINGS });
export const receiveGeneralSettings = (payload) => ({ type: RECEIVE_GENERALSETTINGS, payload });

export const requestCompanyList = () => ({ type: REQUEST_COMPANYLIST });
export const receiveCompanyList = (payload) => ({ type: RECEIVE_COMPANYLIST, payload });

export const requestWebsiteList = (id) => ({ type: REQUEST_WEBSITELIST, data: id });
export const receiveWebsiteList = (payload) => ({ type: RECEIVE_WEBSITELIST, payload });

export const requestUser = () => ({ type: REQUEST_USER });
export const receiveUser = (payload) => ({ type: RECEIVE_USER, payload });

export const requestUptimeMonitor = (data) => ({ type: REQUEST_UPTIME_MONITOR, data: data });
export const receiveUptimeMonitor = payload => ({type: RECEIVE_UPTIME_MONITOR, payload })

export const requestMongos = (data) => ({ type: REQUEST_MONGOS, data: data });
export const receiveMongos = payload => ({type: RECEIVE_MONGOS, payload })


export const requestReport = (data) => ({ type: REQUEST_REPORT, data: data });
export const receiveReport = payload => ({type: RECEIVE_REPORT, payload });

export const requestSsl = (data) => ({ type: REQUEST_SSL, data: data });
export const receiveSsl = payload => ({type: RECEIVE_SSL, payload });

export const requestTickets = (id) => ({ type: REQUEST_TICKETS, data: id });
export const receiveTickets = (payload) => ({ type: RECEIVE_TICKETS, payload });
