import { put, call, takeEvery } from "redux-saga/effects";
import { baseUrl, UptimeKey } from "../enviroment/env";
import moment from 'moment';
import fetch from "isomorphic-fetch";
import * as actions from "./action";

export function fetchGetsApi(call) {
  let wind = "";
  if (typeof window !== "undefined") {
    wind = window.location.host;
  }
  return fetch(baseUrl + call, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url: wind }),
  })
    .then((response) => response.json())
    .then((json) => json.data);
}

export function fetchUserPostsApi(call) {
  let body = JSON.parse(localStorage.getItem("applicationState"));
  return fetch(baseUrl + call, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ company_id: body.theme._id }),
  })
    .then((response) => response.json())
    .then((json) => json.data);
}

export function fetchUserApi(call) {
  let body = JSON.parse(localStorage.getItem("user"));
  let theme_body = JSON.parse(localStorage.getItem("applicationState"));

  return fetch(baseUrl + call, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ 
      email: body.attributes.email,
      company_id: theme_body.theme._id,
    }),
  })
    .then((response) => response.json())
    .then((json) => json.data);
}

export function fetchWebsiteApi(data) {
  return fetch(baseUrl + "users/websites", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ _id: data }),
  })
    .then((response) => response.json())
    .then((json) => json.data);
}

export function fetchUptimeApi(data) {

  let dates = [];
  for(let i = 0;i < 90;i++){
    dates.push(moment().subtract(i,'day').startOf('day').unix("x") +'_'+ moment().subtract(i,'day').endOf('day').unix("x"))
  }

  return fetch(baseUrl + "general/uptime", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      search:data,
      logs: 1,
      all_time_uptime_ratio:1,
      response_times_average:1440,
      response_times:1,
      response_times_start_date: moment().subtract(3,'months').unix(),
      response_times_end_date: moment().unix(),
      logs_start_date:  moment().subtract(3,'months').unix(),
      logs_end_date: moment().unix(),
      custom_uptime_ranges: dates.toString().replaceAll(',','-'),
      custom_uptime_ratios:("1"+'-'+"7"+'-'+"30"+'-'+"90"),
      apikey: UptimeKey,
    }),
  })
    .then((response) => response.json())
    .then((json) => JSON.parse(json.data));

}

export function fetchMongosApi() {
  let wind = "";
  if (typeof window !== "undefined") {
    wind = window.location.host;
  }
  return fetch(baseUrl + "general/mongos", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url: wind }),
  }).then((response) => response.json()).then((json) => json.data);
}
export function fetchReportApi(data){
  let body = JSON.parse(localStorage.getItem("applicationState"));
  return fetch(baseUrl + "general/notification", {
    method: "POST",
    headers: {"Content-Type": "application/json",},    
    body: JSON.stringify({ 
      fileUrl : data[0],
      date : data[1],
      _id:data[2],
      year:data[3],
      company:body.mongos,
      client:data[5]
    }),
  }).then((response) => response.json()).then((json) => json.data);
}
export function fetchSSLApi(data){
  return fetch(baseUrl + "general/ssl", {
    method: "POST",
    headers: {"Content-Type": "application/json",},    
    body: JSON.stringify({ 
      website : data
    }),
  }).then((response) => response.json()).then((json) => json.data);
}


export function* fetchGeneral(action) {
  try {
    const data = yield call(fetchGetsApi, "general");
    yield put({ type: actions.RECEIVE_GENERALSETTINGS, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_GENERALSETTINGS, payload: "loading..." });
  }
}
export function* fetchCompany(action) {
  try {
    const data = yield call(fetchUserPostsApi, "agency");
    yield put({ type: actions.RECEIVE_COMPANYLIST, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_COMPANYLIST, payload: "loading..." });
  }
}
export function* fetchUser(action) {
  try {
    const data = yield call(fetchUserApi, "users");
    yield put({ type: actions.RECEIVE_USER, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_USER, payload: "loading..." });
  }
}
export function* fetchWebsite(action) {
  try {
    const data = yield call(fetchWebsiteApi, action.data);
    yield put({ type: actions.RECEIVE_WEBSITELIST, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_WEBSITELIST, payload: "loading..." });
  }
}
export function* fetchUptime(action) {
  try {
    const data = yield call(fetchUptimeApi, action.data);
    yield put({ type: actions.RECEIVE_UPTIME_MONITOR, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_UPTIME_MONITOR, payload: "loading..." });
  }
}

export function* fetchMongos(action) {
  try {
    const data = yield call(fetchMongosApi, action.data);
    yield put({ type: actions.RECEIVE_MONGOS, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_MONGOS, payload: "loading..." });
  }
}

export function* fetchReport(action) {
  try {
    const data = yield call(fetchReportApi, action.data);
    yield put({ type: actions.RECEIVE_REPORT, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_REPORT, payload: "loading..." });
  }
}

export function* fetchSSL(action) {
  try {
    const data = yield call(fetchSSLApi, action.data);
    yield put({ type: actions.RECEIVE_SSL, payload: data });
  } catch (e) {
    yield put({ type: actions.RECEIVE_SSL, payload: "loading..." });
  }
}

export default function* mySaga() {
  yield takeEvery(actions.REQUEST_GENERALSETTINGS, fetchGeneral);
  yield takeEvery(actions.REQUEST_COMPANYLIST, fetchCompany);
  yield takeEvery(actions.REQUEST_USER, fetchUser);
  yield takeEvery(actions.REQUEST_WEBSITELIST, fetchWebsite);
  yield takeEvery(actions.REQUEST_UPTIME_MONITOR, fetchUptime);
  yield takeEvery(actions.REQUEST_MONGOS, fetchMongos);
  yield takeEvery(actions.REQUEST_REPORT, fetchReport);
  yield takeEvery(actions.REQUEST_SSL, fetchSSL);
}
