import React, { Component } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import Charts from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { arrowUp, arrowPause } from "../images/icons"

export class Monitoring extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id:this.props.data.location.query,
      uptime:'',
      series: [{
        name:'ms',
        data: [[1327359600000,30.95]]
      }],
      options: {
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 350,
          zoom: {
            autoScaleYaxis: true
          }
        },
        annotations: {
          yaxis: [{
            y: 30,
            borderColor: '#999',
            label: {
              show: true,
              text: 'Support',
              style: {
                color: "#fff",
                background: '#00E396'
              }
            }
          }],
          xaxis: [{
            x: new Date('Dec 31 2021').getTime(),
            borderColor: '#999',
            yAxisIndex: 0,
            label: {
              show: true,
              text: 'Rally',
              style: {
                color: "#fff",
                background: '#775DD0'
              }
            }
          }]
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: moment().subtract(-3,'month'),
          max: moment(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: 'MM dd yyyy'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
      },
    
      selection: 'one_year',
    
    };
  }

  componentDidMount(){
    this.props.data.uptime && this.props.data.uptime.monitors.filter((up,index)=>
      this.props.data.location.query ? 
      this.props.data.location.query == up.id 
      && 
      <>
      {this.setState({uptime:up })}
      {this.seriesData(up.response_times)}
      </>
      : index == 0 &&  
      <>
        {this.seriesData(up.response_times)}
        {this.setState({uptime:up})}
      </>
      )
  }

  pageRender = (val) => {
    this.props.data.uptime.monitors.filter((up,index)=>
      up.id == val.target.value && 
      <>
      {this.seriesData(up.response_times)}
      {this.setState({
        id:val.target.value,
        uptime:up
      })}
      </>
    )
  }
  seriesData = (data) => {
    var datasum = [];
    for (var i in data) { datasum.push([parseInt(moment.unix(data[i].datetime).format("x")), data[i].value])};
    this.setState({
      series:[{
        name:'ms',
        data:datasum
      }]
    })
  }
  
  render() {

    const { uptime } = this.state

    let logs = []
    uptime.monitors && uptime.monitors.map(up=>  up.logs.map((log)=>{
       let value = {
        date: moment.unix(log.datetime).format("MM DD YYYY hh:mm:ss"),
        duration: log.duration,
        id: log.id
       }
       logs.push(value)
    }))

    const LogChart = (props) => {
     let data = props.logs.split('-');
     let start = [];
     return data.reverse().map((log,i)=> 
          <div className={"bars"} log={90 -i} date={moment().subtract(i,'day').format("MM DD YYYY hh:mm:ss")} status={log > 99.999 ? 'up':  start.length > 0 ?   log == 0.000 ?  'down' : 'down' : 'null' }>
           
            <div className="tooltip">
               {moment().subtract(89 -i,'day').format("MM DD YYYY hh:mm:ss")} <br /> <span>{log}</span>
             {/* {newData.d.map(log=> <div className="reason">code:{log.reason.code} <br/> detail: {log.reason.detail}</div>)} */}
            </div>
            <em> {parseInt(log) != 0.000 && start.push(1)}</em>
          </div>
      )
    }
    

    return (
      <section className="section auth">
        <div className="container">
          <div className="mb_display">
          
          <>
              <select className="monitorOptions" onChange={(x)=>this.pageRender(x)}>
              {this.props.data.uptime.monitors &&
                this.props.data.uptime.monitors.map((subarray,index) => (
                  <option value={subarray.id} key={index} selected={subarray.id === this.state.id}>{subarray.friendly_name.toLowerCase()} - { subarray.type == 1 ? "HTTP(s)" : subarray.type == 2 ? "Keyword" : subarray.type == 3 ? "Ping" : subarray.type == 4 ? "Port" : ""}</option>
                ))
              }
              </select>
              {this.state.uptime
                  &&
                  <div className="uptimeMonitoring">
                    <h2>{this.state.uptime.friendly_name} - { this.state.uptime.type == 1 ? "HTTP(s)" : this.state.uptime.type == 2 ? "Keyword" : this.state.uptime.type == 3 ? "Ping" : this.state.uptime.type == 4 ? "Port" : ""} is operational</h2>
                    <h4>Checked every minute</h4>
                    
                    <h2>Uptime Last 90 days</h2>
                    <div className="logBar">
                      <LogChart logs={this.state.uptime.custom_uptime_ranges} />
                    </div>
                    <h2>Overall Uptime</h2>
                    <ul className="uptimeMonitoringDetails">
                        {this.state.uptime.custom_uptime_ratio.split("-").map((uptimes,key) => {
                        return <> 
                          {key == 0 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 24 hours</p></li>}
                          {key == 1 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 7 days</p></li>}
                          {key == 2 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 30 days</p></li>}
                          {key == 3 && <li><span className={parseInt(uptimes) > 98.000 ? "up" : "down"}>{parseInt(uptimes).toFixed(0)}%</span><p>Last 90 days</p></li>}
                        </>
                        })}
                    </ul>
                    <h2>Response Time</h2>
                      <Charts  options={this.state.options}  series={this.state.series} type="area" height={350} />
                
                    <h2>Recent events</h2>
                        {this.state.uptime.logs.map((log,index)=> 
                          log.reason.code == '200' ? "" :
                            log.reason.code == '98' ?
                            <div className="logEvent green" key={index}>
                              <h3>{arrowUp} Monitor started </h3>
                              <div className="logEventDate">{moment.unix(log.datetime).format("MM DD YYYY hh:mm:ss")}</div>
                            </div>
                            : log.reason.code == '99' ? 
                            <div className="logEvent gray" key={index}>
                              <h3>{arrowPause} Monitoring was paused</h3>
                              <div className="logEventDate">{moment.unix(log.datetime).format("MM DD YYYY hh:mm:ss")}</div>
                            </div>
                            :  
                            <div className="logEvent red" key={index}>
                              <h3>{arrowUp} {this.state.uptime.friendly_name} was down for {Math.round((log.duration / 60)) < 60 ? Math.round((log.duration / 60)) + " minutes" : Math.round((log.duration / 60 / 60)) + " hours"}  </h3>
                              <div className="logEventDate">{moment.unix(log.datetime).format("MM DD YYYY hh:mm:ss")}</div>
                              <p>The reason was <strong>{log.reason.detail}</strong>. <badge>{log.reason.code}</badge></p>
                              <p className="">
                                <em>Details:</em> The server encountered an unexpected condition that prevented it from fulfilling the request.
                              </p>
                          </div>
                        )}

                    <br/>
                    <br/>
                  </div>
              }
            </>
            
          </div>
        </div>
      </section>
    );
  }
}
export default Monitoring
