import React, { Component } from "react";
import Reportbox from "./Reportbox";

export class MonthlyReport extends Component {
  state = {
    items : this.props.data,
    active: false
  };
  render() {
    return (
      this.state.items && this.state.items.map((report,index)=>
      <div className={this.state.active === index ? "reportToggle active":"reportToggle"} key={index}>
        <h1 onClick={()=>this.setState({active:index === this.state.active ? "":index})}><span></span> Year {report.year}</h1>   
        <div className="Rep">{report.list.map((rplist,ind)=>
          <Reportbox key={ind} datetime={rplist.date} viewLink={rplist.fileUrl} />
        )}</div>
      </div>
      )
    );
  }
}

export default MonthlyReport;
