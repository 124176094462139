import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// export const Button = (props) => {
export const Datepicker = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker selected={startDate} maxDate={startDate} dateFormat="MMMM d, yyyy" onChange={(date) => setStartDate(date)} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
  );
};
