import Skeleton from "react-loading-skeleton";
import React, { Component } from "react"
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class Logo extends Component {
  render(){
    let logo =  this.props.state ? this.props.state.theme && this.props.state.theme.logo : null
    return this.props.state.theme ? (
       <Link className="mainLogo" to={this.props.state.userDetails ? '/dashboard':'/'}>{logo ? <img src={logo} alt="" /> : <img src="https://thebridge140958-dev.s3.amazonaws.com/public/WP-Bridge-Without-Tag%40300x.png" alt="" />}</Link>
    ) : (
      <Skeleton height="80px" width="200px" style={{ marginBottom: "0px" }} />
    ) 
  }
}


const mapStateToProps = (state) => ({state})
export default connect(mapStateToProps)(Logo)