import { Component } from "react";
import { requestMongos, requestReport } from "../../store/action";
import Amplify, { Storage } from "aws-amplify";
import awsmobile from "../../aws-exports";
import API from "@aws-amplify/api";
import { Section, Input, Form } from "../../components/Modules";
import Logo from "../../components/Logo";
import Button from "../../components/Button";
import { Pdf } from "../../images/icons"
import { connect } from "react-redux";
import Moment from 'react-moment';
import moment from 'moment';
Amplify.configure(awsmobile);

class Admin extends Component {
    constructor(props) {
      super(props);
      this.state = {
        mongos: '',
        
        fileforupload: null,
        file_uploaded:null,
        file_err:null,
        fileUrl:'',
        file_name:'Click to Select OR Drag Here',
        buttonValue:true,
        label_class:'',
        file_type:'',
        loading:false,
        fileExtention:{value:'',id:''},
        username:'',
        password:'',
        approved:false
      }
    }
    componentDidMount(){
        this.props.dispatch(requestMongos());
        this.props.dispatch(requestReport());
    }
    
    fileInputHandler = event => {
        if(event.target.value===null || event.target.value===''){
            this.setState({
                buttonValue:true,
                label_class:'',
                fileforupload:null,
                file_name:'Click to Select OR Drag Here'
              })
          }
          else{
            this.setState({
              fileforupload:event.target.files[0],
              file_type:event.target.files[0].type,
              label_class:'dragged-over',
              buttonValue:false,
              
            });
          }
    }
    fileUploadHandler = (id, company, client) => {
        this.setState({loading:true});
        if(this.state.file_type == 'application/pdf'){
        return Storage.put(this.state.fileforupload.name, this.state.fileforupload, {
            level: 'public',
          })
          .then (response => 
            Storage.get(response.key)
            .then(response=>
              this.setState({
                file_uploaded:true,
                fileUrl:response.split('?')[0],
                fileExtention:{value:'',id:id},
                buttonValue:true
              },
                function(){
                    this.props.dispatch(requestReport([this.state.fileUrl,moment().format('LLLL'),id,moment().format('yyyy'), company, client]))
                    setTimeout(() => {
                        this.props.dispatch(requestMongos());
                        this.setState({
                            file_uploaded:null,
                            loading:false,
                            buttonValue:false
                          })
                    }, 1000)
                },
                /*function(){console.log(this.state.fileUrl)}*/
               
            ))
            
          )
          .catch(err => this.setState({file_err:err},console.log(err)))

        }else{
            this.setState({
                fileExtention:{value:'Please select pdf file.',id:id},
                loading:false,
              })
        }

        // return Storage.put(this.state.fileforupload.name, this.state.fileforupload, {
        //     level: 'public',
        //   })
        //   .then(response => Storage.get(response.key))
        //   .then(response=> {
        //       this.setState({
        //           file_uploaded:true,
        //           fileUrl:response.split('?')[0],
        //           buttonValue:true
        //       })
        //       this.props.dispatch(requestReport([this.state.fileUrl,moment().format('LLLL'),id,moment().format('yyyy')]))
        //       setTimeout(() => {
        //         this.props.dispatch(requestMongos());
        //       }, 1000);
        //     }
        //   )
        //   .catch(err => 
        //       this.setState({
        //           file_err:err
        //       })
        //   )
        // this.props.dispatch(requestReport([this.state.fileUrl,moment().format('LLLL'),id,moment().format('yyyy')]))
          
    }
    dragEnterHandler=()=>{
        this.setState({label_class:'dragged-over'});
      }
      dragExitHandler=()=>{
        this.setState({label_class:''});
      }
      approvedPassword = () => {
        if(this.state.username == 'admin' || this.state.password == 'admin123'){
            this.setState({approved:true})
        }else{
            this.setState({approved:false})
            window.location.href="/"
        }
      }
  
    render(){
        const { mongos } = this.props
        return (
            <>
            {!this.state.approved &&
             <Section width={"400px"} padding={"25px"} className="mainContainer">
                <Logo isLoading={true} />
                <div className="alertBox">
                    <Form>
                        <Input type="text" id="username" name="username" value={this.state.username}  placeholder="Username"  onChange={(x)=>this.setState({username:x.target.value})} isLoading={true}/>
                        <Input type="password" id="password" name="password" value={this.state.password}  placeholder="password"  onChange={(x)=>this.setState({password:x.target.value})} isLoading={true}/>
                        <div className="multiBtn alrtButn">
                            <Button title="Submit" type="button" styles="primary"  handleClick={()=>this.approvedPassword()} isLoading={true} />
                        </div>
                    </Form>
                </div>
            </Section>}
            {this.state.approved && mongos.length > 0 &&
            mongos.map((mong,index)=>{
               return  <Section width={"95%"} padding={"25px"} className="mainContainer admin" key={index}>
                   {mong && !mong.logo ?  <h1>{mong.name}</h1> :
                    <img src={mong.logo} alt="" width="300" style={{float:'right'}} /> }
                    <div className="det">
                        <h2>{mong.users.name}</h2>
                        <div className="det_list">
                            <p>Description: <span>{mong.users.description}</span></p>
                            <p>Email: <span>{mong.users.email}</span></p>
                            <p>Type: <span>{mong.users.type}</span></p>
                        </div>
                        <ul>
                        {mong.users.websites.map((web,wbind)=> 
                            <li key={wbind}>{web.name}
                                <ul>
                                    {web.report.map((rp)=> 
                                        <li>
                                            <h3>{rp.year}</h3>
                                            {rp.list.map((lst,lstKey)=> 
                                               <div className="reportList" key={lstKey}>
                                                   
                                                    <div style={{width:'100%'}}>
                                                        <span className="month">
                                                       <Moment format="MMMM">{lst.date}</Moment>
                                                            <a href={lst.fileUrl} target="_new" className="link" >
                                                            Download Report
                                                            </a>
                                                        </span>
                                                        <span className="datetime"><Moment format="DD - YYYY - HH:mm">{lst.date}</Moment></span>
                                                    </div>
                                                    <div className="pdf">
                                                        {Pdf}
                                                    </div>
                                                    {/* <div className="removePdf"></div> */}
                                               </div>
                                            )}
                                            {/* <div className="reportList addMore"></div>  */}
                                               
                                        </li>
                                    )}
                                    
                                </ul>
                                
                                <form>
                                    
                                    <label htmlFor="file" id="upload-event" className={this.state.label_class} >
                                        {this.state.file_name}
                                        <input type="file"  id="file" name="file"  accept=".pdf" onChange={this.fileInputHandler} style={inputStyle} onDragEnter={this.dragEnterHandler} onDragLeave={this.dragExitHandler}/>
                                    </label>
                                    <div>
                                        {this.state.loading ? <button type='button' style={{padding: '10px 20px',margin: '0 auto', display:"table",fontFamily: 'Montserrat',opacity:'0.7'}}>Loading...</button> :  <button type='button' disabled={this.state.buttonValue} onClick={()=>this.fileUploadHandler(web._id, mong.email, mong.users)} 
                                        style={{padding: '10px',margin: '0 auto', display:"table",fontFamily: 'Montserrat'}}>Upload Report</button>}
                                    </div>
                                    <p style={{textAlign:'center',color:"green"}}>
                                        {this.state.file_uploaded ? "Uploaded Successfully" : (this.state.file_uploaded == false ? 'Upload Failed ' + this.state.file_err : this.state.file_uploaded == null && "")}
                                    </p>
                                    {this.state.fileExtention.id == web._id && <p style={{textAlign:'center',color:"red"}}> {this.state.fileExtention.value}</p>}
                                    
                                </form>
                            </li>
                        )}
                        
                        </ul>
                    </div>
                     
                </Section>
            }
            )
        }</>  
        );
    }
}
const inputStyle={
    display: 'table',
    zIndex:99,
    cursor:'pointer',
    margin:"0 auto",
    paddingTop:'10px',
    outline:0
}
const mapStateToProps = (state) => ({ mongos: state.mongos });
export default connect(mapStateToProps)(Admin);